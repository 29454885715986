import { MatchingData, SelectedRowsDict } from "@pulsar/matching/types";

export type MatchingActions = {
  type:
    | "SET_ERROR"
    | "SET_RESULTS"
    | "SET_PENDING"
    | "SET_LOADING"
    | "SET_NAME"
    | "SET_SELECTED_ROWS"
    | "SET_MAX_CANDIDATES"
    | "SET_MAX_CANDIDATES_FINISHED_LOADING";
  payload?: any;
};
export type SubmitStatus = {
  isLoading: boolean;
  isWip: boolean;
};

export type MatchingState = {
  status: string | null;
  job_id: string | null;
  results: MatchingData[] | null;
  submitStatus: SubmitStatus;
  selectedRows: SelectedRowsDict;
  name: string;
  maxCandidates: { isLoading: boolean; number: number };
};
export const defaultInitialState = {
  status: null,
  job_id: null,
  results: null,
  submitStatus: { isLoading: false, isWip: false },
  name: "",
  selectedRows: {},
  maxCandidates: { isLoading: false, number: 1 },
};

export function matchReducer(
  state: MatchingState,
  action: MatchingActions
): MatchingState {
  switch (action.type) {
    case "SET_ERROR":
      return { ...state, status: "Error" };
    case "SET_RESULTS":
      return { ...state, status: "Done", results: action.payload.results };
    case "SET_PENDING":
      return { ...state, status: "Pending", job_id: action.payload.job_id };
    case "SET_LOADING":
      return {
        ...state,
        submitStatus: {
          isLoading: action.payload.isLoading,
          isWip: action.payload.isWip,
        },
      };
    case "SET_NAME":
      return { ...state, name: action.payload.name };
    case "SET_SELECTED_ROWS":
      return { ...state, selectedRows: action.payload.selectedRows };
    case "SET_MAX_CANDIDATES":
      return {
        ...state,
        maxCandidates: { number: action.payload.number, isLoading: true },
      };
    case "SET_MAX_CANDIDATES_FINISHED_LOADING":
      return {
        ...state,
        maxCandidates: { ...state.maxCandidates, isLoading: false },
      };
    default:
      throw new Error();
  }
}
