import React from "react";
import { useCompanyInfo } from "../utils/hooks";
import SubRow from "./SubRow";
import { theme } from "../utils/pulsarTheme";

// ------ Helper Functions ----------
function formatCompanyInfoData(data, original) {
  if (!data) return null;
  const company_name = data.data.attributes.name;
  return {
    ...data.data.attributes,
    ...original,
    company_name,
  };
}
/**
 *
 * React component that Lazily async loads company rows
 */
export default function CompanyRowAsync({
  row,
  rowProps,
  visibleColumns,
  fetchRowKey,
}) {
  const iri = row.original[fetchRowKey];
  const [{ data }, controller] = useCompanyInfo(iri);
  const formattedData = formatCompanyInfoData(data, row.original);
  React.useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  // TODO: Implement an on-error for those cases where company info will yield errors even on several tries
  return (
    <SubRow
      row={row}
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      data={formattedData}
      loading={formattedData ? false : true}
      rowStyle={{ backgroundColor: theme.palette.backgrounds.main }}
    />
  );
}
