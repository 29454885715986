import { CircularProgress, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";

import Table from "../components/Table";
import { useCancelableAuthedSWR } from "../utils/hooks";
import {
  ClientsQueue,
  FiltersQueue,
  PriorityQueue,
  TemplatesQueue,
  updatePriorityQueue,
} from "../utils/priorityQueueUtils";
import {useOktaAuth} from "@okta/okta-react";

const useStyles = makeStyles((theme) => ({
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
    fontSize: "0.5em",
  },
}));

export default function Priority(): JSX.Element {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const queueUri = `${process.env.REACT_APP_API}/priority/queue`;
  const { authState } = useOktaAuth();
  const [{ data, error, mutate }] = useCancelableAuthedSWR(queueUri, {
    revalidateOnFocus: false,
  });
  const [priorityQueue, setPriorityQueue] = React.useState<PriorityQueue>({
    clients: [],
    filters: [],
    templates: [],
  });
  React.useEffect(() => {
    setPriorityQueue(data);
  }, [data]);

  const onBlurEdit = React.useCallback(
    (
      type: keyof PriorityQueue,
      row: any,
      column: any
    ): React.FocusEventHandler<HTMLInputElement> | undefined => {
      return async (event: any) => {
        const clone = [...priorityQueue[type]];
        clone[row.index] = {
          ...priorityQueue[type][row.index],
          [column.id]: event.target.value,
        };
        const newQueue = {
          ...priorityQueue,
          [type]: clone,
        };
        setPriorityQueue(newQueue);
      };
    },
    [priorityQueue]
  );

  const columnsTemplate = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "period",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("templates", row, column)}
          />
        ),
      },
      {
        Header: "Template",
        accessor: "template",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("templates", row, column)}
          />
        ),
      },
      {
        Header: "Queue Percentage",
        accessor: "queue_percentage",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("templates", row, column)}
          />
        ),
      },
    ],
    [onBlurEdit]
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "period",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("clients", row, column)}
          />
        ),
      },
      {
        Header: "User Id",
        accessor: "id",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("clients", row, column)}
          />
        ),
      },
      {
        Header: "Queue Percentage",
        accessor: "queue_percentage",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("clients", row, column)}
          />
        ),
      },
    ],
    [onBlurEdit]
  );
  const columnsFilter = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "period",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("filters", row, column)}
          />
        ),
      },
      {
        Header: "filter Id",
        accessor: "id",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("filters", row, column)}
          />
        ),
      },
      {
        Header: "Queue Percentage",
        accessor: "queue_percentage",
        disableFilter: true,
        Cell: ({
          column,
          value,
          row,
        }: {
          column: any;
          value: any;
          row: any;
        }) => (
          <input
            defaultValue={value}
            onBlur={onBlurEdit("filters", row, column)}
          />
        ),
      },
    ],
    [onBlurEdit]
  );
  const deleteMethod = React.useCallback(
    (type: keyof PriorityQueue) => ({
      tableData,
      selectedRows,
    }: {
      tableData: any;
      selectedRows: any;
    }) => {
      const deleted = new Set<TemplatesQueue | FiltersQueue | ClientsQueue>(
        tableData.filter((_e: any, index: any) => selectedRows[index])
      );
      const newQueue = {
        ...priorityQueue,
        [type]: (priorityQueue[type] as any[]).filter((e) => !deleted.has(e)),
      };
      setPriorityQueue(newQueue);
    },
    [priorityQueue]
  );

  const addMethod = React.useCallback(
    (template: any, key: keyof PriorityQueue) => () => {
      return (
        <button
          onClick={() =>
            setPriorityQueue({
              ...priorityQueue,
              [key]: [...priorityQueue[key], template],
            })
          }
        >
          add
        </button>
      );
    },
    [priorityQueue]
  );
  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        classes={{ label: classes.iconButtonLabel }}
        aria-label="save"
        color="primary"
        size="medium"
        onClick={async () => {
          try {
            await updatePriorityQueue({
              priorityQueue: priorityQueue,
              token: authState.accessToken!.accessToken,
            });
            mutate(priorityQueue);
            enqueueSnackbar("Saved Priority Queue", {
              variant: "success",
            });
          } catch (e) {
            enqueueSnackbar(`Error saving ${e}`, {
              variant: "error",
            });
          }
        }}
      >
        <SaveIcon />
        Save
      </IconButton>
      <h2>Filter </h2>
      {error && !priorityQueue ? (
        <div>There was an error fetching cases {JSON.stringify(error)}</div>
      ) : !priorityQueue ? (
        <CircularProgress />
      ) : (
        <div>
          <div style={{ margin: "1em" }}>
            <Table
              title={"User Filters Queue"}
              data={priorityQueue.clients}
              columns={columns}
              setData={() => {}}
              updateMyData={() => {}}
              customRowRender={null}
              skipPageReset={false}
              renderRowSubComponent={() => {}}
              selectedRows={[]}
              onSelectedRowsChange={() => {}}
              hasDelete
              addComponent={addMethod(
                {
                  period: "P1D",
                  id: "default",
                  queue_percentage: 0,
                } as ClientsQueue,
                "clients"
              )}
              deleteHandler={deleteMethod("clients")}
            />
          </div>
          <div style={{ margin: "1em" }}>
            <Table
              title={"Filters Queue"}
              data={priorityQueue.filters}
              columns={columnsFilter}
              setData={() => {}}
              updateMyData={() => {}}
              customRowRender={null}
              skipPageReset={false}
              renderRowSubComponent={() => {}}
              selectedRows={[]}
              onSelectedRowsChange={() => {}}
              hasDelete
              addComponent={addMethod(
                {
                  period: "P1D",
                  id: "default",
                  queue_percentage: 0,
                } as FiltersQueue,
                "filters"
              )}
              deleteHandler={deleteMethod("filters")}
            />
          </div>
          <div style={{ margin: "1em" }}>
            <Table
              title={"Template Queue"}
              data={priorityQueue.templates}
              columns={columnsTemplate}
              setData={() => {}}
              updateMyData={() => {}}
              customRowRender={null}
              skipPageReset={false}
              renderRowSubComponent={() => {}}
              selectedRows={[]}
              onSelectedRowsChange={() => {}}
              hasDelete
              addComponent={addMethod(
                {
                  period: "P1D",
                  template: "default",
                  queue_percentage: 0,
                } as TemplatesQueue,
                "templates"
              )}
              deleteHandler={deleteMethod("templates")}
            />
          </div>
        </div>
      )}
    </>
  );
}
