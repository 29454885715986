export function formatCompanyInfoData(data: any) {
  const company_name = data.data.attributes.name;
  const company = data.data.id;
  return {
    ...data,
    ...data.data.attributes,
    company,
    company_name,
  };
}

export function categorizeMarketCap(value: number) {
  return value > 10000000000
    ? "Large"
    : value > 2000000000
    ? "Medium"
    : value > 0
    ? "Small"
    : null;
}
