import { CompanyData } from "@pulsar/matching/types";
import { CircularProgress, IconButton } from "@material-ui/core";
import { WithOptional } from "@pulsar/utils/types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRouter, useCancelableAuthedSWR } from "../utils/hooks";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuItem from "@material-ui/core/MenuItem";
import Table, { SelectColumnFilter } from "../components/Table";
import SearchComponent from "../components/Search";
import {
  addCompaniesOnFilter,
  allSignalTypes,
  FilterByIdResponse,
  getIrisFromCompanyFilter,
  getSignalTypes,
  setFilterName,
  updateSignalTypesFilter,
  updateFilter,
  removeCompaniesOnFilter,
} from "../utils/filterEndpointUtils";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { SearchResultData } from "semantic-ui-react/dist/commonjs/modules/Search/Search";
import TextField from "@material-ui/core/TextField";
import CompanyRowAsync from "../components/CompanyRowAsync";
import { sortByExchange } from "../utils/marketDataUtils";
import {useOktaAuth} from "@okta/okta-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
};
const Filter = () => {
  const { id } = useParams<{ id: string }>();
  const { authState } = useOktaAuth();
  const history = useHistory();
  const { navigate } = useRouter();
  const filters = `${process.env.REACT_APP_API}/filters/${id}`;
  const [{ data, error, mutate }] = useCancelableAuthedSWR(filters, {
    revalidateOnFocus: false,
  });
  const [filterName, setName] = useState("");
  const types = getSignalTypes(data);
  const [companyData, setCompanyData] = useState<
    WithOptional<CompanyData, "company_name">[]
  >([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newData = updateSignalTypesFilter(
      data,
      event.target.value as string[]
    );
    mutate(newData, false);
  };

  // Format the iris in the filter every time the filter data changes
  // this includes when new companies are added to the filter manually
  React.useEffect(() => {
    const abortController = new AbortController();
    async function fetchData() {
      try {
        const irisInFilter = getIrisFromCompanyFilter(data).map((company) => ({
          company,
        }));
        setCompanyData(irisInFilter);
        setName(data?.data?.attributes?.name);
      } catch (e) {
        if (!abortController.signal.aborted) {
          //TODO: Do something if the request failed and it was not aborted
        }
      }
    }
    fetchData();

    return () => {
      abortController.abort();
    };
  }, [data, authState.accessToken]);

  const customRowRender = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <CompanyRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        fetchRowKey="company"
      />
    ),
    []
  );

  const saveFilter = async (filter: FilterByIdResponse, filterName: string) => {
    const updatedFilter = setFilterName(filter, filterName);
    try {
      await updateFilter({
        id: filter.data.id,
        filter: updatedFilter,
        token: authState.accessToken!.accessToken,
      });
      navigate("/filters");
    } catch (e: any) {
      //TODO: Do proper error handling
      console.log(e);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "City",
        accessor: "city",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "State",
        accessor: "state",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Country",
        accessor: "country",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Duns",
        accessor: "duns",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Market Data",
        accessor: "market_data",
        filter: "includes",
        disableFilter: true,
        Cell: ({ value }: { value: any }) => {
          const sortedMarketData = value ? sortByExchange(value) : null;
          return (
            <ul style={{ listStyle: "none", padding: 0 }}>
              {sortedMarketData?.listings.map((exchangeData) => {
                const { exchange_label, ticker } = exchangeData;
                return (
                  <li>
                    <span>
                      <b>{`${exchange_label}: `}</b>
                      {ticker}
                    </span>
                  </li>
                );
              })}
            </ul>
          );
        },
      },
      {
        Header: "Url",
        accessor: "url",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    []
  );
  const handleResultSelect = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>, result: SearchResultData) => {
      const newData = addCompaniesOnFilter(data, result.result.iri);
      mutate(newData, false);
    },
    [data, mutate]
  );
  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }
  const renderAddSearchComponent = React.useCallback(() => {
    return (
      <SearchComponent
        onResultSelect={handleResultSelect}
        icon={"add"}
        placeholder={"Add a company"}
      />
    );
  }, [handleResultSelect]);

  const handleRemoveCompany = React.useCallback(
    ({ tableData, selectedRows }) => {
      const irisToDelete = new Set(
        (tableData as { company: string }[])
          .filter((e, index) => selectedRows[index])
          .map((e) => e.company)
      );
      const newData = removeCompaniesOnFilter(data, irisToDelete);
      mutate(newData, false);
    },
    [data, mutate]
  );

  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <h2>Filter </h2>
      {error || data?.message ? (
        <div>There was an error fetching cases</div>
      ) : !data ? (
        <CircularProgress />
      ) : (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl style={{ minWidth: "15em" }}>
              <TextField
                id="filter-name"
                value={filterName}
                defaultValue={data.data.id}
                onChange={handleChangeName}
                label="Filter"
              />
            </FormControl>
            <FormControl style={{ minWidth: "15em", marginTop: "1em" }}>
              <InputLabel id="demo-mutiple-chip-label">Signal Types</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={types}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ margin: "2px" }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {allSignalTypes.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ margin: "1em" }}>
              <Table
                title={"Companies"}
                data={companyData}
                columns={columns}
                setData={() => {}}
                updateMyData={() => {}}
                skipPageReset={false}
                renderRowSubComponent={() => {}}
                customRowRender={customRowRender}
                selectedRows={[]}
                onSelectedRowsChange={() => {}}
                hasDelete
                addComponent={renderAddSearchComponent}
                deleteHandler={handleRemoveCompany}
              />
            </div>

            <Button
              style={{ minWidth: "20em", width: "100%" }}
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              onClick={() => saveFilter(data, filterName)}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
