import { createMuiTheme } from "@material-ui/core";

const theme = {
  palette: {
    primary: {
      main: "#025568",
      activeText: "white",
      inactiveText: "#9d9c9c",
      subtitleText: "#70757a",
    },
    secondary: {
      main: "#00a6e1",
    },
    contrast: {
      main: "#b23977",
    },
    backgrounds: {
      main: "#effbff",
    },
  },
};
const PulsarMuiTheme = createMuiTheme(theme);

export { PulsarMuiTheme, theme };
