import React from "react";
import Table, { SelectColumnFilter } from "./Table";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CompanyRowAsync from "../components/CompanyRowAsync";
import DeepSearch from "../components/DeepSearch";
import SubRow from "./SubRow";
import { theme } from "../utils/pulsarTheme";
import { sortByExchange } from "../utils/marketDataUtils";

// ------------- Main UI Component ----------
const BetterMatchingTable = ({
  initialData,
  initialSelectedRows = {},
  renderOnSaveButtons,
}) => {
  const [data, setData] = React.useState(initialData);
  const [selectedRows, setSelectedRows] = React.useState(initialSelectedRows);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander",
        Header: "Expand/Collapse",
        Cell: (props) => {
          const { row } = props;
          return row.canExpand ? (
            row.isExpanded ? (
              <div style={{ display: "flex" }}>
                <IconButton
                  aria-label="collapse"
                  size="small"
                  {...row.getToggleRowExpandedProps()}
                >
                  <ArrowDownwardIcon fontSize="inherit" />
                </IconButton>
              </div>
            ) : (
              <IconButton
                aria-label="expand"
                size="small"
                {...row.getToggleRowExpandedProps()}
              >
                <ArrowForwardIcon fontSize="inherit" />
              </IconButton>
            )
          ) : null;
        },
        SubCell: () => null,
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "City",
        accessor: "city",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "State",
        accessor: "state",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Country",
        accessor: "country",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Duns",
        accessor: "duns",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Market Data",
        accessor: (row) => row.market_data ?? row.ticker, // Rows can render either a simple string ticker or market data object
        filter: "includes",
        disableFilter: true,
        Cell: ({ value }) => {
          // TODO: Move this to TS to avoid this undocumented type of value: string | MarketData
          // The 3 scenarios are market data being undefined, string or market data
          // the cell can't return undefined in render, thus we make sure value is defined at least
          if (!value?.listings) return value ?? null;
          const sortedMarketData = sortByExchange(value);
          return (
            <ul style={{ listStyle: "none", padding: 0 }}>
              {sortedMarketData?.listings.map((exchangeData) => {
                const { exchange_label, ticker } = exchangeData;
                return (
                  <li>
                    <span>
                      <b>{`${exchange_label}: `}</b>
                      {ticker}
                    </span>
                  </li>
                );
              })}
            </ul>
          );
        },
      },
      {
        Header: "Url",
        accessor: "url",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: ({ value }) => {
          return (
            <a href={`//${value}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        },
      },
    ],
    []
  );

  const renderCandidatesSubRowComponent = React.useCallback(
    ({ row, rowProps, visibleColumns, parentRow, appendSubRows }) => {
      /* Check if the row contains an iri to lazily fetch it's content
       * Currently if there's no iri we assume the data won't be fetched lazily
       * and we render whatever is in there
       */
      return (
        <>
          {row.original?.iri ? (
            <CompanyRowAsync
              row={row}
              rowProps={rowProps}
              visibleColumns={visibleColumns}
              fetchRowKey="iri"
            />
          ) : (
            <SubRow
              row={row}
              rowProps={rowProps}
              visibleColumns={visibleColumns}
              data={{ ...row.original, company_name: row.original.name }}
              rowStyle={{ backgroundColor: theme.palette.backgrounds.main }}
            />
          )}
          {/* Check if it's the last subRow to show Deep Search capability at the end of the section*/}
          {row.id === parentRow.subRows[parentRow.subRows.length - 1].id && (
            <DeepSearch
              parentRow={parentRow}
              visibleColumns={visibleColumns}
              appendSubRows={appendSubRows}
            />
          )}
        </>
      );
    },
    []
  );

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls appendSubRows, we'll use
  // the rowIndex and new value to update the
  // original data.
  const appendSubRows = (rowIndex, subRows) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            subRows: [...row.subRows, ...subRows],
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <Table
        title={"Companies"}
        columns={columns}
        data={data}
        setData={setData}
        updateMyData={appendSubRows}
        skipPageReset={skipPageReset}
        renderRowSubComponent={renderCandidatesSubRowComponent}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
      />
      {renderOnSaveButtons({ data, selectedRows })}
    </>
  );
};

export default BetterMatchingTable;
