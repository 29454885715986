import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";
import BaseLineAlignedTableCell from "./BaseLineAlignedTableCell";

export default function SubRow({
  row,
  rowProps,
  visibleColumns,
  data,
  loading,
  rowStyle,
}) {
  if (loading) {
    return (
      <TableRow>
        <TableCell />
        <TableCell colSpan={visibleColumns.length - 1}>
          <Typography variant="h4">
            <Skeleton />
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  // error handling here :)
  return (
    <TableRow
      {...rowProps}
      key={`${rowProps.key}-expanded-${row.index}`}
      style={rowStyle}
    >
      {row.cells.map((cell) => {
        return (
          <BaseLineAlignedTableCell {...cell.getCellProps()}>
            {cell.render(cell.column.SubCell ? "SubCell" : "Cell", {
              value:
                cell.column.accessor && cell.column.accessor(data, row.index),
              row: { ...row, original: data },
            })}
          </BaseLineAlignedTableCell>
        );
      })}
    </TableRow>
  );
}
