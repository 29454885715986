import React, { FC } from "react";
import {Router, Route, Switch, useHistory} from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import Home from "./views/Home";
import history from "./utils/history";
import "semantic-ui-css/semantic.min.css";

// styles
import "./App.css";
import { IconButton, ThemeProvider } from "@material-ui/core";
import { PulsarMuiTheme } from "./utils/pulsarTheme";
import { CALLBACK_PATH, OKTA_AUTH } from "./react-okta-spa";
import {LoginCallback, SecureRoute, Security, useOktaAuth} from "@okta/okta-react";
import {toRelativeUrl} from "@okta/okta-auth-js";

interface CloseSnackbarProps {
  id: string | number | undefined;
}

const CloseButton: FC<CloseSnackbarProps> = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

function CustomLoginCallback() {
  const { oktaAuth } = useOktaAuth();
  return (
      <LoginCallback
          errorComponent={() => {
            const id = setTimeout(() => {
              localStorage.clear()
              window.location.assign("/");
            }, 2000);
            oktaAuth.signOut().then(() => {
              clearTimeout(id);
            });
            return null;
          }}
      />
  );
}

function App() {
  const reactHistory = useHistory();

  const restoreOriginalUri = async (_: any, originalUri: string) => {
    reactHistory.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
        <Security oktaAuth={OKTA_AUTH} restoreOriginalUri={restoreOriginalUri}>
          <div id="app">
            <ThemeProvider theme={PulsarMuiTheme}>
              <SnackbarProvider
                  maxSnack={3}
                  action={(key) => <CloseButton id={key} />}
              >
                <Switch>
                  <Route path={CALLBACK_PATH} component={CustomLoginCallback} />
                  <SecureRoute path="/" component={Home} />
                </Switch>
              </SnackbarProvider>
            </ThemeProvider>
          </div>
        </Security>
  );
}

export default function () {
  return (
    <Router history={history}>
      <App />
    </Router>
  );
}
