import { Button, CircularProgress, IconButton } from "@material-ui/core";
import React, {useEffect, useReducer, useState} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoadingButton from "../components/LoadingButton";
import { defaultInitialState, matchReducer } from "../utils/matchReducer";
import {
  getSelectedSubRows,
  isSameOpenCorporatesCompany,
  normalizeSelectedCompanies,
  updateJob,
} from "../utils/matchUtils";
import { useCancelableAuthedSWR, useRouter } from "../utils/hooks";
import BetterMatchingTable from "../components/BetterMatchingTable";
import {
  CandidateData,
  IriCompany,
  MatchingData,
  OpenCorporatesCompany,
  SelectedRowsDict,
} from "@pulsar/matching/types";
import { createFilter, Element } from "../utils/filterEndpointUtils";
import {useOktaAuth} from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js/lib/types/UserClaims"

export default function SavedMatch(props: any) {
  const { id } = useParams<{ id: string }>();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | undefined>();
  const { navigate } = useRouter();
  const history = useHistory();
  const location = useLocation<{ name: string }>();
  const matchEndpoint = `${process.env.REACT_APP_API}/matching_jobs/${id}`;
  const [{ data, error, mutate }] = useCancelableAuthedSWR(matchEndpoint, {
    revalidateOnFocus: false,
  });
  const [{ results, submitStatus, selectedRows }, dispatch] = useReducer(
    matchReducer,
    defaultInitialState
  );

  // Check if the data changed and update state
  useEffect(() => {
    if (data && data[0].job_body.data) {
      setResults(data[0].job_body.data);
      updateSelectedRows(data[0].job_body.selectedRows);
    }
  }, [data]);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(undefined);
    } else {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  // Action Creators
  function setResults(results: MatchingData[]) {
    dispatch({
      type: "SET_RESULTS",
      payload: { results },
    });
  }

  function updateSelectedRows(selectedRows: SelectedRowsDict) {
    dispatch({ type: "SET_SELECTED_ROWS", payload: { selectedRows } });
  }

  //The data and the state lives within the child component
  //we build memoized render props to callback with the updated state
  // and to stabilize the reference to the fn to prevent re-renders
  const memoizedSaveChanges = React.useCallback(
    async function handleSaveChanges({
      tableData,
      selectedRows,
    }: {
      tableData: MatchingData[];
      selectedRows: SelectedRowsDict;
    }) {
      dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
      // Find any non-iri company and using the inserter generate a valid iri
      const resultArray = await normalizeSelectedCompanies({
        tableData,
        selectedRows,
        token: authState.accessToken!.accessToken,
      });
      // TODO: Don't ignore failed insertions and apply an agreed flow
      const fullFilledCompanies = resultArray
        .map(
          (r) =>
            r as PromiseFulfilledResult<{
              result: any;
              company: OpenCorporatesCompany;
            }>
        )
        .filter((r) => r.value);

      // To avoid flickering on the UI, we need to respect the original order
      const updatedTable = tableData.map((row) => ({
        ...row,
        subRows: row.subRows.map((subRow) => {
          const createdIri = fullFilledCompanies.find((result) =>
            isSameOpenCorporatesCompany(
              result.value.company,
              subRow as OpenCorporatesCompany
            )
          )?.value.result.data.iri;
          if (createdIri) {
            return {
              iri: createdIri,
            };
          } else {
            return subRow;
          }
        }),
      }));

      try {
        await updateJob({
          id: parseInt(id),
          userEmail: userInfo?.email!,
          name: location?.state?.name,
          body: { data: updatedTable, selectedRows },
          token: authState.accessToken!.accessToken,
          isWip: false,
        });
        // SWR offers this to safely update the cache, since SWR uses urls as keys
        // if we don't mutate manually the above update won't refresh the cache
        mutate([
          {
            ...data[0],
            job_body: { ...data.job_body, data: updatedTable, selectedRows },
          },
        ]);
        return navigate("/matching");
      } catch (error) {
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    },
    [data, authState.accessToken, id, location, mutate, navigate, userInfo]
  );

  const memoizedCreateFilter = React.useCallback(
    async function handleCreateFilter({
      tableData,
      selectedRows,
    }: {
      tableData: MatchingData[];
      selectedRows: SelectedRowsDict;
    }) {
      dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
      const payload: Element = {
        class: "company",
        property: "id",
        // TODO: Make sure it's clear what's expected when creating a filter out of an un-saved
        // non iri Company, for the time being we will ignore it.
        values: getSelectedSubRows({ tableData, selectedRows })
          .filter((subRow) => (subRow as IriCompany).iri)
          .map((subRow: CandidateData) => (subRow as IriCompany).iri),
      };
      try {
        const filter = await createFilter({
          elements: [payload],
          token: authState.accessToken!.accessToken,
        });
        return navigate(`/filters/${filter.data.id}`);
      } catch (error) {
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    },
    [authState.accessToken, navigate]
  );

  const renderButtons = React.useCallback(
    ({ data, selectedRows }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          flexWrap: "wrap",
          marginTop: "1em",
        }}
      >
        <LoadingButton loading={submitStatus.isLoading}>
          <Button
            onClick={() =>
              memoizedCreateFilter({ tableData: data, selectedRows })
            }
            disabled={submitStatus.isLoading}
            color="primary"
            variant="outlined"
          >
            Create Filter
          </Button>
        </LoadingButton>
        <LoadingButton loading={submitStatus.isLoading}>
          <Button
            onClick={() =>
              memoizedSaveChanges({ tableData: data, selectedRows })
            }
            disabled={submitStatus.isLoading}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </LoadingButton>
      </div>
    ),
    [memoizedCreateFilter, memoizedSaveChanges, submitStatus.isLoading]
  );
  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <h2>{location?.state?.name ?? "Not found"}</h2>
      {error && <div>There was an error fetching this job, retrying</div>}
      {!data && <CircularProgress />}
      {results && (
        <>
          <BetterMatchingTable
            initialData={results ?? []}
            initialSelectedRows={selectedRows}
            renderOnSaveButtons={renderButtons}
          />
        </>
      )}
    </>
  );
}
