import { MarketData } from "@pulsar/matching/types";

/**
 * Sort a MarketData object listings by primary exchange (if any) and then exchange alphabetical order
 */
function sortByExchange({
  primary_exchange,
  listings,
}: MarketData): MarketData {
  const sortedMarketData = {
    primary_exchange: primary_exchange,
    listings: listings.sort((x, y) => {
      const isXPrimary = x.exchange_label === primary_exchange;
      const isYPrimary = y.exchange_label === primary_exchange;
      if (x.exchange_label < y.exchange_label) {
        if (isYPrimary) {
          return 1;
        } else {
          return -1;
        }
      }
      if (x.exchange_label > y.exchange_label) {
        if (isXPrimary) {
          return -1;
        } else {
          return 1;
        }
      }
      return 0;
    }),
  };

  return sortedMarketData;
}

export { sortByExchange };
