import React, { useState } from "react";
import { useCancelableAuthedSWR, useQuery } from "../utils/hooks";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import Table, {
  SelectColumnFilter,
  HumanValidationColumnFilter,
  DateColumnFilter,
  filterDateInMonthYear,
  SourceColumnFilter,
  filterPublisherInArticles,
} from "../components/Table";
import startCase from "lodash.startcase";
import { CircularProgress, IconButton } from "@material-ui/core";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import { getSignals } from "../utils/signalsUtils";
import dayjs from "dayjs";
import {useOktaAuth} from "@okta/okta-react";

// ISO String date format => YYYY-MM-DD needed by the api's
function toIsoStringDate(date?: Date) {
  return dayjs(date).format("YYYY-MM-DD").toString();
}

// Default range set to 1 week starting 1 day before TODAY
// given that the DB doesn't cache signals for the current day to avoid
// issues when loading the content
function getDefaultRange(daysAgo = 7, offset = 1) {
  const date_end = dayjs().subtract(offset, "day").toDate();
  const date_begin = dayjs()
    .subtract(offset + daysAgo, "day")
    .toDate();
  return { date_begin, date_end };
}

type SignalsState = {
  data?: Array<any>;
  message?: string;
  error?: string | null;
};

export default function Signals() {
  let query = useQuery();
  const history = useHistory();
  const { authState } = useOktaAuth();

  // ---- Signals Api param state ----
  const initialFilterId = query.get("filter_id");
  const initialDateBegin = query.get("date_begin");
  const initialDateEnd = query.get("date_end");
  const { date_begin, date_end } = getDefaultRange();
  const [filterId, setFilterId] = useState(initialFilterId);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: initialDateBegin ? new Date(initialDateBegin) : date_begin,
    endDate: initialDateEnd ? new Date(initialDateEnd) : date_end,
  });

  // Filters Fetch
  const filters = `${process.env.REACT_APP_API}/filters`;
  const [{ data: filtersData }, filtersController] = useCancelableAuthedSWR(
    filters,
    {
      revalidateOnFocus: false,
    }
  );

  // Signals Fetch
  const [data, setData] = useState<SignalsState>();

  React.useEffect(() => {
    const signalsEndpoint = initialFilterId
      ? `/signals?filter_ids=${filterId}&date_begin=${toIsoStringDate(
          dateRange.startDate
        )}&date_end=${toIsoStringDate(dateRange.endDate)}`
      : null;
    const abortController = new AbortController();
    async function fetchData() {
      if (signalsEndpoint) {
        setData({ error: null });
        try {
          const signals = await getSignals({
            key: signalsEndpoint,
            token: authState.accessToken!.accessToken,
            controller: abortController,
          });
          setData({ data: signals.data, error: null });
        } catch (e) {
          if (!abortController.signal.aborted) {
            setData({ error: e });
          }
        }
      }
    }
    fetchData();

    return () => {
      abortController.abort();
      filtersController.abort();
    };
  }, [
    dateRange.endDate,
    dateRange.startDate,
    filterId,
    filtersController,
    authState.accessToken,
    initialFilterId,
  ]);

  // Handlers
  function handleChange(value: DateRange | unknown, paramName: string) {
    if (paramName === "dateRange") {
      const dateRange = value as DateRange;
      const startDate = toIsoStringDate(dateRange.startDate);
      const endDate = toIsoStringDate(dateRange.endDate);
      query.set("date_begin", startDate!);
      query.set("date_end", endDate!);
      setDateRange(value as DateRange);
    } else {
      query.set(paramName, value as string);
      setFilterId(value as string);
    }
    history.replace(`/signals?${query.toString()}`);
  }
  const toggle = () => setOpen(!open);

  // Memoized Table Column Definition
  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "attributes.category",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Snippet",
        accessor: "attributes.snippet",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableFilter: true,
      },
      {
        Header: "Publication Date",
        accessor: "attributes.publication_date",
        Filter: DateColumnFilter,
        filter: filterDateInMonthYear,
        Cell: ({ value }: { value: string }) => {
          return <time>{new Date(value).toDateString()}</time>;
        },
        getCellExportValue: (row: any) => {
          return toIsoStringDate(row.original.attributes.publication_date);
        },
      },
      {
        Header: "Full Story",
        accessor: "attributes.articles",
        Filter: SourceColumnFilter,
        filter: filterPublisherInArticles,
        Cell: ({ value }: { value: any }) => {
          return (
            <ul style={{ listStyle: "none", padding: 0 }}>
              {value
                // Make unique per an
                .filter(
                  (value: { an: string }, index: any, arr: any[]) =>
                    arr.findIndex((art) => art.an === value.an) === index
                )
                .map((article: { an: string; publisher: string }) => {
                  return (
                    <li style={{ minWidth: "15em" }}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://global.factiva.com/redir/default.aspx?P=sa&an=${article.an}`}
                      >
                        {article.publisher}
                      </a>
                    </li>
                  );
                })}
            </ul>
          );
        },
        getCellExportValue: (row: any) => {
          const articles = row.original.attributes.articles;
          const publisherAnMap = articles
            // Make unique per an
            .filter(
              (value: { an: string }, index: any, arr: any[]) =>
                arr.findIndex((art) => art.an === value.an) === index
            )
            .map((article: { an: string; publisher: string }) => {
              return `${article.publisher}: https://global.factiva.com/redir/default.aspx?P=sa&an=${article.an}`;
            })
            .sort();
          return publisherAnMap.join("\n");
        },
      },
      {
        Header: "Validation",
        accessor: "attributes.is_validated",
        Filter: HumanValidationColumnFilter,
        filter: "equals",
        Cell: ({ value }: { value: boolean }) => {
          return value ? (
            <CheckCircleOutlineRoundedIcon
              style={{
                color: "#64a342",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          ) : (
            <BlockRoundedIcon
              style={{
                color: "#FF6347",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          );
        },
      },
      {
        Header: "Spans",
        id: "spans",
        accessor: "attributes.signal_properties",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableFilter: true,
        Cell: ({ row }: { row: any }) => {
          const signalProperties = row.original.attributes.signal_properties;
          return (
            <ul style={{ listStyle: "none", padding: 0 }}>
              {Object.keys(signalProperties).map((propertyKey: string) => {
                return signalProperties[propertyKey].map(
                  (kv: { text: string; iri: string }) => {
                    const { iri, text } = kv;
                    return (
                      <li style={{ minWidth: "15em" }}>
                        <b>{startCase(propertyKey)}</b>:{" "}
                        {iri ? <a href={iri}>{text}</a> : <span>{text}</span>}
                      </li>
                    );
                  }
                );
              })}
            </ul>
          );
        },
        getCellExportValue: (row: any) => {
          // Build a line break separated string with the list of key: value signal proerpties
          // We are only including property prefLables for the time being (no iris)
          const signalProperties = row.original.attributes.signal_properties;
          const keyValueProperties = Object.keys(signalProperties)
            .map((propertyKey: string) => {
              return signalProperties[propertyKey].map(
                (kv: { text: string; iri: string }) => {
                  return `${startCase(propertyKey)}: ${kv.text}`;
                }
              );
            })
            .sort();
          return keyValueProperties.join("\n");
        },
      },
    ],
    []
  );

  // Main UI Component
  return (
    <div>
      <h1>Signals</h1>
      <div style={{ margin: "1em 0" }}>
        <InputLabel id="demo-simple-select-label">Filter Id</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterId}
          onChange={(e) => handleChange(e.target.value, "filter_id")}
        >
          {filtersData?.data.map((filter: any) => (
            <MenuItem key={filter.id} value={filter.id}>
              {filter.attributes?.name || filter.id}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <InputLabel id="date-range">Date Range</InputLabel>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="Close notification"
            color="inherit"
            onClick={toggle}
          >
            <DateRangeIcon fontSize="small" />
          </IconButton>
          <span>{dateRange.startDate?.toDateString()}</span>
          <ArrowRightAltIcon />
          <span>{dateRange.endDate?.toDateString()}</span>
        </div>
      </div>
      <DateRangePicker
        open={open}
        toggle={toggle}
        onChange={(range) => handleChange(range, "dateRange")}
        initialDateRange={dateRange}
      />
      {filterId ? (
        !data?.data || data?.message ? (
          <div>
            {data?.error ? (
              <div>There was an error fetching signals, please try again</div>
            ) : (
              <CircularProgress />
            )}
          </div>
        ) : (
          <Table
            columns={columns}
            data={data.data}
            setData={() => {}}
            updateMyData={() => {}}
            skipPageReset={false}
            renderRowSubComponent={() => {}}
            customRowRender={null}
            selectedRows={[]}
            onSelectedRowsChange={() => {}}
          />
        )
      ) : null}
    </div>
  );
}
