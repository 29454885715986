import { OktaAuth } from '@okta/okta-auth-js';

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const CALLBACK_PATH = '/auth/callback';

const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`;
const HOST = window.location.host;
const REDIRECT_URI = `${window.location.protocol}//${HOST}${CALLBACK_PATH}`;
export const OKTA_CONFIG = {
    issuer: ISSUER,
    clientId: OKTA_CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email']
};

export const OKTA_AUTH = new OktaAuth(OKTA_CONFIG);