import { LinearProgress } from "@material-ui/core";
import { CompanyData } from "@pulsar/matching/types";
import { SuggestedCompetitor } from "@pulsar/types/competitors";
import React from "react";
import { CellProps } from "react-table";

import { categorizeMarketCap, formatCompanyInfoData } from "../utils/company";
import { keepNew } from "../utils/competitors";
import { useFetchCompany, useFetchInBatches } from "../utils/hooks";
import ControlledTable, {
  filterMarketCapInCategories,
  MarketCapFilter,
  SelectColumnFilter,
} from "./ControlledTable";
import _ from "lodash";

interface CompetitorsTableProps {
  data: Array<{ company: string } | { iri: string }>;
  title: string;
  selectedRows?: any;
  onSelectRowsChange?: (...args: any) => void;
  cacheKey?: string;
}

function CompetitorsTable({
  data,
  title,
  selectedRows,
  onSelectRowsChange,
  cacheKey,
}: CompetitorsTableProps) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Rank",
        accessor: "order",
        filter: "equals",
        Cell: ({ value, row }: { value?: number; row: any }) => {
          const assertedValue = value ?? row.index; // Not all competitors have server rank
          return <>{assertedValue + 1}</>;
        },
        Filter: SelectColumnFilter,
        disableFilter: true,
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableFilter: true,
      },
      {
        Header: "Ticker",
        id: "ticker",
        filter: "includes",
        Filter: SelectColumnFilter,
        disableFilter: true,
        accessor: (originalRow: any) => {
          const primaryExchange = originalRow?.market_data?.primary_exchange;
          const listings = originalRow?.market_data?.listings;
          const primaryTicker =
            originalRow?.market_datta?.primary_listing?.ticker;
          // We get the min length ticker as a hack since we don't have the primary ticker in the response.
          // Since it's usually the shortest one, we select that as the primary one
          //TODO: Remove the shortest trick once primary tickers have been all loaded
          const ticker =
            primaryTicker ??
            _.chain(listings)
              .filter(
                (listing: { exchange_label: string }) =>
                  listing.exchange_label === primaryExchange
              )
              .map("ticker")
              .minBy((x: string) => x.length)
              .value();
          return ticker;
        },
      },
      {
        Header: "Industry",
        filter: "includes",
        Filter: SelectColumnFilter,
        accessor: (originalRow: any) => {
          const industry =
            originalRow?.djid?.preferred_label ||
            originalRow?.djii?.preferred_label;
          return industry;
        },
      },
      {
        Header: "Market Cap",
        id: "market_cap.value",
        accessor: "market_cap.value",
        Filter: MarketCapFilter,
        filter: filterMarketCapInCategories,
        Cell: ({ value }: CellProps<any>) => {
          return <span>{categorizeMarketCap(value)}</span>;
        },
      },
      {
        Header: "Exchange",
        accessor: "market_data.primary_exchange",
        filter: "equals",
        Filter: SelectColumnFilter,
      },
    ],
    []
  );

  const selectedRowIds = React.useMemo(
    () =>
      selectedRows.reduce(
        (acc: { [x: string]: boolean }, curr: { company: string }) => {
          acc[curr.company] = true;
          return acc;
        },
        {} as { [key: string]: Boolean }
      ),
    [selectedRows]
  );

  const { fetchCompany, controller } = useFetchCompany(formatCompanyInfoData);
  // Aboort pending requests on unmount
  React.useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  const {
    loading: areBatchesLoading,
    state: suggestedPopulated,
  } = useFetchInBatches<
    { company: string } | { iri: string },
    CompanyData & SuggestedCompetitor
  >(data, 100, fetchCompany, undefined, keepNew);

  return (
    <>
      {areBatchesLoading ? (
        <LinearProgress
          variant="determinate"
          value={Math.ceil((suggestedPopulated.length / data.length) * 100)}
        />
      ) : (
        <ControlledTable
          data={suggestedPopulated}
          columns={columns}
          title={title}
          setData={() => {}}
          updateMyData={() => {}}
          customRowRender={null}
          skipPageReset={false}
          renderRowSubComponent={() => {}}
          selectedRows={selectedRowIds}
          onSelectedRowsChange={onSelectRowsChange}
          getRowId={(row: any, relativeIndex: any, parent: any) =>
            parent ? [parent.id, row.company].join(".") : row.company
          }
          hasPagination={false}
          tableElementCustomProps={{ stickyHeader: true }}
          tableFooterCustomProps={{
            style: {
              left: 0,
              bottom: 0, // <-- KEY
              zIndex: 2,
              position: "sticky",
              backgroundColor: "white",
            },
          }}
        />
      )}
    </>
  );
}

export default CompetitorsTable;
