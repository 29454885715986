import fetcherWithToken from "./fetcher";

export type FiltersReponse = {
  data: FilterResponse[];
};
export type FilterResponse = {
  id: string;
  type: string;
  attributes: Attributes;
};
export type Attributes = {
  name: string;
  elements: Element[];
};
export type Element = {
  class: string;
  property: string;
  values: string[];
};
export type FilterByIdResponse = {
  data: FilterResponse;
};

export function updateFilter({
  id,
  filter,
  token,
}: {
  id: string;
  filter: FilterByIdResponse;
  token: string;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/filters/${id}`,
    token,
    {
      method: "PUT",
      body: JSON.stringify(filter),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function deleteFilter({
  id,
  token,
}: {
  id: string;
  token: string;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/filters/${id}`,
    token,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export function deleteFilters({
  ids,
  token,
}: {
  ids: string[];
  token: string;
}) {
  return Promise.all(ids.map((id) => deleteFilter({ id, token })));
}

export function createFilter({
  elements,
  token,
}: {
  elements: Element[];
  token: string;
}) {
  const payload = {
    data: {
      type: "filter",
      attributes: {
        elements,
      },
    },
  };
  return fetcherWithToken(`${process.env.REACT_APP_API}/filters`, token, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getIrisFromCompanyFilter(data: FilterByIdResponse): string[] {
  const companies = (data?.data?.attributes?.elements || []).filter(
    (e) => e.class === "company"
  );
  return companies.length === 0 ? [] : companies[0].values;
}

export function getSignalTypes(data: FilterByIdResponse): string[] {
  const types = (data?.data?.attributes?.elements || []).filter(
    (e) => e.class === "signal" && e.property === "type"
  );
  return types.length === 0 ? [] : types[0].values;
}

export function updateSignalTypesFilter(
  data: FilterByIdResponse,
  values: string[]
): FilterByIdResponse {
  const elements = data.data.attributes.elements
    .filter((e: Element) => e.class !== "signal" && e.property !== "type")
    .concat({
      class: "signal",
      property: "type",
      values: values,
    });
  const newData = {
    data: {
      ...data.data,
      attributes: {
        ...data.data.attributes,
        elements: elements,
      },
    },
  };

  return newData;
}
export function addCompaniesOnFilter(data: FilterByIdResponse, iri: string) {
  const iris: string[] = getIrisFromCompanyFilter(data);
  const elements = data.data.attributes.elements
    .filter((e: Element) => e.class !== "companies" && e.property !== "id")
    .concat({
      class: "company",
      property: "id",
      values: [iri].concat(iris.filter((e) => e !== iri)),
    });

  const newData = {
    data: {
      ...data.data,
      attributes: {
        ...data.data.attributes,
        elements: elements,
      },
    },
  };
  return newData;
}
export function setFilterName(
  data: FilterByIdResponse,
  name: string
): FilterByIdResponse {
  const newData = {
    data: {
      ...data.data,
      attributes: {
        ...data.data.attributes,
        name: name,
      },
    },
  };
  return newData;
}

export function removeCompaniesOnFilter(
  data: FilterByIdResponse,
  irisToDelete: Set<string>
) {
  const iris: string[] = Array.from(getIrisFromCompanyFilter(data));
  const elements = data.data.attributes.elements
    .filter((e: Element) => e.class !== "companies" && e.property !== "id")
    .concat({
      class: "company",
      property: "id",
      values: iris.filter((iri) => !irisToDelete.has(iri)),
    });
  const newData = {
    data: {
      ...data.data,
      attributes: {
        ...data.data.attributes,
        elements: elements,
      },
    },
  };
  return newData;
}
export const allSignalTypes: string[] = [
  "fin_distress",
  "financial_crime",
  "sanctions",
  "regulatory_issues",
  "acquisitions",
  "mergers",
  "divestitures",
  "management_changes_join",
  "management_changes_leave",
  "company_expansion",
  "workforce_expansion",
  "workforce_reduction",
  "relocation",
  "facilities_opening",
  "facilities_closing",
  "awards",
  "company_creation",
  "competitors",
  "global_footprint",
];
