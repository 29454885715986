import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useExportData } from "react-table-plugins";

import { categorizeMarketCap } from "../utils/company";
import BaseLineAlignedTableCell from "./BaseLineAlignedTableCell";
import TablePaginationActions from "./TablePaginationActions";
import TableToolbar from "./TableToolbar";

// UI Helpers

function defaultGetExpdefortFileBlob(tableArgs) {
  const { columns, data, fileType } = tableArgs;
  const selectedIndexes = columns[0].filteredRows
    .filter((row) => row.isSelected)
    .map((row) => row.id);

  const dataToExport =
    selectedIndexes.length > 0
      ? data.filter((_row, index) => selectedIndexes.includes(index.toString()))
      : data;

  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data: dataToExport });
    return new Blob([csvString], { type: "text/csv" });
  }
  // Other formats goes here
  return false;
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

// --------------- Custom Column Filters  -------------------------

//Default Select Filters Options
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].sort();
  }, [id, preFilteredRows]);
  // Render a multi-select box

  return (
    <div style={{ padding: "1em" }}>
      <InputLabel htmlFor={id}>{Header}</InputLabel>
      <Select
        fullWidth
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        displayEmpty
        renderValue={(value) => {
          // MaterialUI doesn't render empty values as labels
          // We need them to "reset" the filter to no value
          return <MenuItem value={value}>{value ? value : "All"}</MenuItem>;
        }}
      >
        <MenuItem value={""} key={0}>
          {"All"}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
// Validation Select Filters Options
export function HumanValidationColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box

  return (
    <div style={{ padding: "1em" }}>
      <InputLabel htmlFor={id}>{Header}</InputLabel>
      <Select
        fullWidth
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        displayEmpty
        renderValue={(value) => {
          // MaterialUI doesn't render empty values as labels
          // We need them to "reset" the filter to no value
          return <MenuItem value={value}>{value ? value : "All"}</MenuItem>;
        }}
      >
        <MenuItem value={""} key={0}>
          {"All"}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {option ? "Human Validated" : "Unvalidated"}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
// Date Custom Filter and Helpers
function getNormalizedDateYear(date) {
  return Date.parse(`${date.getFullYear()}-${date.getMonth() + 1}`);
}
export function filterDateInMonthYear(rows, id, filterValue) {
  return rows.filter((row) => {
    const date = new Date(row.values[id]);
    const normalizedDate = getNormalizedDateYear(date);
    return normalizedDate === filterValue;
  });
}
export function DateColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      const date = new Date(row.values[id]);
      const normalizedDate = getNormalizedDateYear(date);
      options.add(normalizedDate);
    });
    return [...options.values()].sort();
  }, [id, preFilteredRows]);
  // Render a multi-select box

  const formatMonthYear = (value) => {
    const dateFormattingOptions = {
      year: "numeric",
      month: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", dateFormattingOptions).format(
      value
    );
  };
  return (
    <div style={{ padding: "1em" }}>
      <InputLabel htmlFor={id}>{Header}</InputLabel>
      <Select
        fullWidth
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        displayEmpty
        renderValue={(value) => {
          // MaterialUI doesn't render empty values as labels
          // We need them to "reset" the filter to no value
          return (
            <MenuItem value={value}>
              {value ? formatMonthYear(value) : "All"}
            </MenuItem>
          );
        }}
      >
        <MenuItem value={""} key={0}>
          {"All"}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {formatMonthYear(option)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

// Default Select Filters Options
export function filterPublisherInArticles(rows, id, filterValue) {
  // If there's nothing selected in the filterValye array we must
  // return all rows as-is without filtering
  if (filterValue && filterValue.length < 1) return rows;
  return rows.filter((row) => {
    const signalPublishers = row.values[id].map((article) => article.publisher);
    // We only care for at least one publisher included on the filter
    // use some to filter in O(M+N) time complexity
    return signalPublishers.some((publisher) =>
      filterValue.includes(publisher)
    );
  });
}
export function SourceColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows, and adding all nested article publisher names
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      row.values[id].map((article) => options.add(article.publisher));
    });
    return [...options.values()].sort();
  }, [id, preFilteredRows]);
  // Render a multi-select box
  return (
    <div style={{ padding: "1em" }}>
      <InputLabel id={id} htmlFor={id}>
        Publisher
      </InputLabel>
      <Select
        labelId={id}
        fullWidth
        value={filterValue ?? []}
        onChange={(e) => setFilter(e.target.value || undefined)}
        displayEmpty
        multiple
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export function MarketCapFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(categorizeMarketCap(row.values[id]));
    });
    return [...options.values()].sort();
  }, [id, preFilteredRows]);
  // Render a multi-select box

  return (
    <div style={{ padding: "1em" }}>
      <InputLabel htmlFor={id}>{Header}</InputLabel>
      <Select
        fullWidth
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        displayEmpty
        renderValue={(value) => {
          // MaterialUI doesn't render empty values as labels
          // We need them to "reset" the filter to no value
          return <MenuItem value={value}>{value ? value : "All"}</MenuItem>;
        }}
      >
        <MenuItem value={""} key={0}>
          {"All"}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export function filterMarketCapInCategories(rows, id, filterValue) {
  return rows.filter(
    (row) => categorizeMarketCap(row.values[id]) === filterValue
  );
}

// -------------- Main Component ---------------
function Table({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  renderRowSubComponent,
  customRowRender,
  selectedRows,
  onSelectedRowsChange,
  hasDelete = false,
  hasSelect = true,
  addComponent = null,
  deleteHandler = ({ tableData, selectedRows, columns }) => {},
  title = "",
  getRowId = undefined,
  getExportFileBlob = defaultGetExpdefortFileBlob,
  tableElementCustomProps = {},
  tableBodyCustomProps = {},
  tableFooterCustomProps = {},
  toolBarConfig = undefined,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    getToggleHideAllColumnsProps,
    allColumns,
    exportData,
  } = useTable(
    {
      columns,
      data,
      getRowId,
      initialState: { selectedRowIds: selectedRows },
      getExportFileBlob,
      // autoResets help control state when data changes
      // we can decide to avoid wiping certain states with this
      autoResetPage: !skipPageReset,
      autoResetExpanded: false,
      autoResetSortBy: false,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExportData,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const selectColumn = {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            );
          },
          SubCell: (props) => {
            return (
              <div>
                <IndeterminateCheckbox
                  {...props.row.getToggleRowSelectedProps()}
                />
              </div>
            );
          },
        };
        return hasSelect ? [selectColumn, ...columns] : columns;
      });
    }
  );

  // Keep parent/store state in sync with local state
  // No need to update on mount since we are passing initial state
  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  const handleChangePage = (_event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  function handleDelete() {
    deleteHandler &&
      deleteHandler({
        tableData: data,
        selectedRows: selectedRowIds,
        columns: columns,
      });
  }
  // Hold the parent row value when iterating over subRows so that it can be used by any subRow before switching
  // to the next parent
  let parentRow = null;
  // Render the UI for your table
  const defaultToolBarProps = {
    hasLocalSearch: true,
    hasFilterColumns: true,
    hasRowFilters: true,
    hasDelete: false,
  };
  const toolbarProps = {
    ...defaultToolBarProps,
    ...toolBarConfig,
  };
  return (
    <>
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        allColumns={allColumns}
        headeerGroup={headerGroups}
        hasDelete={hasDelete}
        addComponent={addComponent}
        deleteHandler={handleDelete}
        title={title}
        exportData={exportData}
        {...toolbarProps}
      />
      <MaUTable {...getTableProps(tableElementCustomProps)}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <BaseLineAlignedTableCell
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}
                  {column.id !== "selection" ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  ) : null}
                </BaseLineAlignedTableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps(tableBodyCustomProps)}>
          {page.map((row, i) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            if (row.subRows.length > 0) {
              parentRow = row;
            }

            //TODO: Abstract this to a component that lazily loads the page content for filter
            return (
              <>
                {/* If the row is not and expanded one we can render normally
                  else we use the render prop and delegate.

                  This approach works when the table itself has nested data, else all rows
                  will have depth 0 by default
               */}
                {row.depth === 0 ? (
                  customRowRender ? (
                    customRowRender({ row, rowProps, visibleColumns })
                  ) : (
                    <TableRow {...rowProps}>
                      {row.cells.map((cell) => {
                        return (
                          <BaseLineAlignedTableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </BaseLineAlignedTableCell>
                        );
                      })}
                    </TableRow>
                  )
                ) : (
                  <>
                    {renderRowSubComponent({
                      row,
                      rowProps,
                      visibleColumns,
                      parentRow: parentRow,
                      appendSubRows: updateMyData,
                    })}
                  </>
                )}
              </>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                10,
                50,
                100,
                // TODO: Fix pagination on subRows to avoid offering the sum of all subRows as "All"
                {
                  label: "All",
                  value: data.reduce((acc, curr) => {
                    acc += curr?.subRows ? curr.subRows.length : 1;
                    return acc;
                  }, 0),
                },
              ]}
              colSpan={visibleColumns.length}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              {...tableFooterCustomProps}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};

export default Table;
