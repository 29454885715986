import {
  Chip,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import {
  SearchFilter,
  SearchState,
  Suggestion,
  Ticker,
} from "@pulsar/search/types";
import _ from "lodash";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { Search, SearchResultData, SearchResultProps } from "semantic-ui-react";

import { get } from "../utils/matchUtils";
import {useOktaAuth} from "@okta/okta-react";

// TODO: Expand static configuration options
const MIN_SEARCH_CHARACTERS = 2;
const availableFilters = [
  { name: "All", value: "*" },
  { name: "Fcode", value: "fcode" },
  { name: "Ticker", value: "ticker" },
  { name: "Duns", value: "duns" },
  { name: "Name", value: "name" },
];

// Results come from elastic search with a nested <b> element showing the highlight
// both the styling and the markup are needed to render them nicely
const HighlightSpan = styled("span")({
  "& b": {
    color: "black",
  },
});

function createMarkup(html: string) {
  // TODO: Purge any incoming html string for malicious code, we are currently trusting our own service
  return { __html: html };
}

const initialState: SearchState = { isLoading: false, results: [], value: "" };

/**
 * Return the search results showing only the active search params by receiving first the active filters
 */
const resultRenderer = (activeFilters: SearchFilter[]) => (
  props: SearchResultProps & Suggestion
) => {
  const { reason, company_data } = props;
  const { name, country, duns, fcode, ticker, primary_ticker } = company_data;
  // Avoid conflicting names between reasons and company data
  const {
    name: nameReason,
    duns: dunsReason,
    fcode: fcodeReason,
    ticker: tickerReason,
  } = reason;

  // Try to read the primary if not since it's usually the shortest one, we select that as the primary one
  // TODO: Remove the shortest one once we have the new tickers loaded
  const mainTicker =
    primary_ticker ??
    _.chain(ticker)
      .minBy((x: Ticker) => x.ticker.length)
      .value();
  const areAllFieldsActive = activeFilters.includes("*");
  return (
    <div key="content" className="content">
      {name &&
        (nameReason ? (
          <div
            dangerouslySetInnerHTML={createMarkup(nameReason.matching_part)}
          />
        ) : (
          <div>{name}</div>
        ))}
      <div
        style={{
          marginTop: "0.25em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {country && <b className="price">{country}</b>}
        {duns && (activeFilters.includes("duns") || areAllFieldsActive) && (
          <div className="description">
            <span>{`DUNS: `}</span>
            <HighlightSpan
              dangerouslySetInnerHTML={createMarkup(
                dunsReason?.matching_part ?? duns
              )}
            />
          </div>
        )}
        {fcode && (activeFilters.includes("fcode") || areAllFieldsActive) && (
          <div className="description" style={{ textTransform: "uppercase" }}>
            <span>{`FCODE: `}</span>
            <HighlightSpan
              dangerouslySetInnerHTML={createMarkup(
                fcodeReason?.matching_part ?? fcode
              )}
            />
          </div>
        )}
        {mainTicker &&
          (activeFilters.includes("ticker") || areAllFieldsActive) && (
            <div className="description" style={{ textTransform: "uppercase" }}>
              <span>{`TICKER: `}</span>
              <HighlightSpan
                dangerouslySetInnerHTML={createMarkup(
                  tickerReason?.matching_part ??
                    `${mainTicker.exchange}: ${mainTicker.ticker}`
                )}
              />
            </div>
          )}
      </div>
    </div>
  );
};
function SearchComponent({
  onResultSelect,
  icon = "search",
  placeholder = "Company Search",
  filterKeys,
}: {
  onResultSelect: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData
  ) => void;
  icon?: string;
  placeholder?: string;
  filterKeys?: Array<SearchFilter>;
}) {
  const memoizedFilters = filterKeys ?? (["*"] as Array<SearchFilter>);
  const [{ isLoading, value, results }, setState] = useState(initialState);
  const { authState } = useOktaAuth();
  const [activeFilters, setActiveFilters] = React.useState<SearchFilter[]>(
    memoizedFilters
  );
  const memoizedSearchResultRenderer = React.useCallback(
    (props) => {
      const withFilters = resultRenderer(activeFilters);
      return withFilters(props);
    },
    [activeFilters]
  );
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    // Reset other options in case "ALL" is selected
    if (value.includes("*") && !activeFilters.includes("*")) {
      setActiveFilters(["*"]);
    } else {
      const rest = value.filter((v) => v !== "*") as SearchFilter[];
      setActiveFilters(rest);
    }
  };

  useEffect(() => {
    if (value.length < MIN_SEARCH_CHARACTERS) {
      return setState({ ...initialState, value });
    }
    setState((prevState) => {
      return { ...prevState, isLoading: true, value };
    });
    const controller = new AbortController();
    async function fetchData() {
      const token = authState.accessToken?.accessToken;
      const filterParams = activeFilters.reduce((acc, curr) => {
        acc[`filter[${curr}]`] = value;
        return acc;
      }, {} as { [key: string]: string });

      get(`${process.env.REACT_APP_API}/suggestions/companies`, filterParams, {
        headers: { Authorization: `Bearer ${token}` },
        signal: controller.signal,
      })
        .then(async (results) => {
          const formattedResults = (await results.json()) as Suggestion[];
          setState((prevState) => {
            return {
              ...prevState,
              isLoading: false,
              results: formattedResults,
            };
          });
        })
        .catch(() => {});
    }
    fetchData();
    return () => {
      controller.abort();
    };
  }, [activeFilters, authState.accessToken, value]);
  return (
    <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Filter</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={activeFilters}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {(selected as string[]).map((value) => {
                const name =
                  availableFilters.find((f) => f.value === value)?.name ??
                  value;
                return <Chip key={value} label={name} />;
              })}
            </div>
          )}
          // MenuProps={MenuProps}
        >
          {availableFilters.map(({ name, value }) => {
            return (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>Choose your search criteria</FormHelperText>
      </FormControl>
      <Search
        size="large"
        fluid
        icon={icon}
        loading={isLoading}
        placeholder={placeholder}
        onSearchChange={debounce(
          (e: any, bar: any) => {
            setState((prevState) => {
              return {
                ...prevState,
                value: bar.value,
              };
            });
          },
          500,
          { leading: true }
        )}
        minCharacters={MIN_SEARCH_CHARACTERS}
        results={results}
        resultRenderer={memoizedSearchResultRenderer}
        onResultSelect={(e, data) => {
          onResultSelect(e, data);
          setState(initialState);
        }}
        value={value}
      />
    </div>
  );
}
export default SearchComponent;
