import React, { PropsWithChildren } from "react";
import {
  CircularProgress,
  ExtendButtonBase,
  ButtonTypeMap,
} from "@material-ui/core";

interface Props extends Partial<ExtendButtonBase<ButtonTypeMap<{}, "button">>> {
  loading: boolean;
  containerStyle?: React.CSSProperties;
}

export default function LoadingButton(props: PropsWithChildren<Props>) {
  const { loading, containerStyle, children } = props;
  return (
    <div style={{ position: "relative", ...containerStyle }}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-12px",
            marginTop: "-12px",
          }}
        />
      )}
    </div>
  );
}
