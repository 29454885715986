import qs from "querystring";
import {
  IriCompany,
  MatchingData,
  MatchResponse,
  MatchState,
  OpenCorporatesCompany,
  SelectedRowsDict,
} from "@pulsar/matching/types";
import fetcherWithToken from "./fetcher";

function buildMatchingDataFromMatchingApi(
  matchResponse: MatchResponse
): MatchingData[] {
  return matchResponse.result.map((result) => {
    const {
      company_name,
      city = null,
      state = null,
      country = null,
      duns = null,
      url = null,
      ticker = null,
    } = result.provided_company_data;
    return {
      company_name,
      city,
      state,
      country,
      duns,
      url,
      ticker,
      subRows: result.candidates.map((candidate) => {
        return {
          iri: candidate.iri,
        };
      }),
    };
  });
}
async function buildMatchingDataFromCsv({
  rawStringCsv,
  token
}: {
  rawStringCsv: string;
  token: string;
}) {
  const matchResponse = await fetcherWithToken(
    `${process.env.REACT_APP_API}/companies/match/execute`,
    token,
    {
      method: "POST",
      body: rawStringCsv,
      headers: {
        "Content-Type": "text/csv",
      },
    }
  );
  return matchResponse;
}

async function fetchMatchingJob({
  jobId,
  token,
}: {
  jobId: string;
  token: string;
}) {
  const queryParams = qs.stringify({
    job_id: jobId,
  });

  const matchResponse = await fetcherWithToken(
    `${process.env.REACT_APP_API}/companies/match/status?${queryParams}`,
    token,
    {
      method: "GET",
    }
  );
  return matchResponse;
}

function insertJob({
  userEmail,
  name,
  body,
  token,
  isWip,
}: {
  userEmail: string;
  name: string;
  body: MatchState;
  token: string;
  isWip?: boolean;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/matching_jobs`,
    token,
    {
      method: "POST",
      body: JSON.stringify({
        last_updated_by: userEmail,
        name,
        job_body: body,
        is_wip: isWip,
      }),
      headers: {
        "Content-Type": "application/json"
      },
    }
  );
}

function updateJob({
  id,
  userEmail,
  name,
  body,
  token,
  isWip,
}: {
  id: number;
  userEmail: string;
  name: string;
  body: MatchState;
  token: string;
  isWip?: boolean;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/matching_jobs/${id}`,
    token,
    {
      method: "PUT",
      body: JSON.stringify({
        id,
        last_updated_by: userEmail,
        name,
        job_body: body,
        is_wip: isWip,
        created_at: new Date().toISOString(), //TODO: POSTGREST forces you to send all columns so we are rewriting the creation date, we might be able to avoid this with an upsert
        updated_at: new Date().toISOString(),
      }),
      headers: {
        "Content-Type": "application/json"
      },
    }
  );
}
/**
 *
 * @param idsToDelete comma separated string of ids
 * @param getToken async fn to retrieve the bearer token from
 */
function deleteJob({
  idsToDelete,
  token,
}: {
  idsToDelete: string[];
  token: string;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/matching_jobs/${idsToDelete.join(",")}`,
    token,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
    }
  );
}
function formatQueryParams(
  paramsObject:
    | string
    | URLSearchParams
    | string[][]
    | Record<string, string>
    | undefined
) {
  const params = new URLSearchParams(paramsObject);
  return params.toString();
}
function get(
  url: string,
  queryParamsObj?:
    | string
    | URLSearchParams
    | string[][]
    | Record<string, string>
    | undefined,
  params?: RequestInit
) {
  const formattedUrl = queryParamsObj
    ? `${url}?${formatQueryParams(queryParamsObj)}`
    : url;
  return fetch(formattedUrl, {
    ...params,
  });
}

function getSelectedSubRows({
  tableData,
  selectedRows,
}: {
  tableData: MatchingData[];
  selectedRows: SelectedRowsDict;
}) {
  // In order to avoid cycling through the whole table we built a helper dict
  // to access selected subrows from the table directly.
  const idsDict = Object.keys(selectedRows)
    .filter((id) => id.includes("."))
    .map((id) => ({
      // The table syntax for handling row ids is "X.Y" where
      // X represents the parent row id and Y the child collapsed row id
      parentId: parseInt(id.split(".")[0]),
      childId: parseInt(id.split(".")[1]),
    }));

  // Parent rows can be ignored, as they are provided records by the user without an IRI attached
  const selectedSubRows = Object.values(idsDict).map(
    (idObj) => tableData[idObj.parentId].subRows[idObj.childId]
  );
  return selectedSubRows;
}

async function normalizeSelectedCompanies({
  tableData,
  selectedRows,
  token,
}: {
  tableData: MatchingData[];
  selectedRows: SelectedRowsDict;
  token: string;
}) {
  const selectedSubRows = getSelectedSubRows({
    tableData,
    selectedRows,
  });
  const nonIriSelectedCompanies = selectedSubRows.filter(
    (subRow) => !(subRow as IriCompany).iri
  ) as OpenCorporatesCompany[];
  return Promise.allSettled(
    nonIriSelectedCompanies.map((company) =>
      insertOpenCorporates({ company, token })
    )
  );
}

function insertOpenCorporates({
  company,
  token,
}: {
  company: OpenCorporatesCompany;
  token: string;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/inserter/open_corporates?jurisdiction=${company.id.jurisdictionCode}&company_number=${company.id.companyNumber}`,
    token,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((result) => ({ result, company }));
}

function isSameOpenCorporatesCompany(
  a: OpenCorporatesCompany,
  b?: OpenCorporatesCompany
) {
  return (
    a.id.companyNumber === b?.id?.companyNumber &&
    a.id.jurisdictionCode === b?.id?.jurisdictionCode
  );
}

export {
  fetchMatchingJob,
  buildMatchingDataFromCsv,
  buildMatchingDataFromMatchingApi,
  insertJob,
  updateJob,
  deleteJob,
  get,
  getSelectedSubRows,
  normalizeSelectedCompanies,
  isSameOpenCorporatesCompany,
};
