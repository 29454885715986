import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  styled,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CompanyData } from "@pulsar/matching/types";
import { SuggestedCompetitor } from "@pulsar/types/competitors";
import React, { useCallback, useMemo, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { SearchResultData } from "semantic-ui-react";

import CompetitorsResultTable from "../components/CompetitorsResultTable";
import CompetitorsTable from "../components/CompetitorsTable";
import { useCompetitorsController } from "../utils/competitors";
import {
  useCompanyInfo,
  useCompetitors,
  useConfirmBrowserExit,
  useInferredCompetitors,
  useSuggestedCompetitors,
} from "../utils/hooks";
import { theme } from "../utils/pulsarTheme";
import _ from "lodash";

interface CompetitorsProps {}

const StyledSubtitle = styled("span")({
  marginLeft: "1em",
  color: theme.palette.primary.subtitleText,
});

function Competitors(props: CompetitorsProps) {
  const { iri } = useParams<{ iri: string }>();
  const { save } = useCompetitorsController();
  const history = useHistory();
  // State initalizers
  const [expanded, setExpanded] = React.useState<string | false>("high");
  const [resultingCompanies, setResultingCompanies] = useState<
    Array<(SuggestedCompetitor & CompanyData) | { company: string }>
  >([]);
  // Services custom hooks
  const [{ data: companyData }] = useCompanyInfo(iri);
  const [{ data: suggested, error: suggestedError }] = useSuggestedCompetitors(
    iri
  );
  const [{ data: competitors, mutate: mutateCompetitors }] = useCompetitors(
    iri
  );
  const { inferrredCompetitors } = useInferredCompetitors(resultingCompanies);
  /**
   * Break competitor Iris into low and high to allow the UI to fetch data for only
   * a subset of them depending onn what section is being rendered. Use memo to only do it
   * once if nothing has changed
   */
  const {
    highConfidenceCompetitors,
    lowConfidenceCompetitors,
  } = useMemo(() => {
    const competitors = suggested?.competitors ?? [];
    return {
      suggestedCompetitors: competitors,
      highConfidenceCompetitors: competitors.filter(
        (c) => c.confidence === "High"
      ),
      lowConfidenceCompetitors: competitors.filter(
        (c) => c.confidence !== "High"
      ),
    };
  }, [suggested]);

  //TODO: Replace with useSyncResultingWithInitialSelected

  React.useEffect(() => {
    // Sync initial selected suggestions with resulting companies
    const initialResulting =
      competitors?.data.competitors?.map((c) => ({ company: c })) ?? [];
    setResultingCompanies(initialResulting);
  }, [competitors]);

  // Handlers
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onSelectCandidateChange = useCallback(
    // This handler will get executed when mounting the layout to sync selected rows
    // and when a checkbox is clicked.
    (selected, isSelected) => {
      setResultingCompanies((currentRes) => {
        if (isSelected) {
          return [...currentRes, selected];
        } else {
          return currentRes.filter((s) => s.company !== selected.company);
        }
      });
    },
    []
  );

  const onManualAddHandler = useCallback(
    (
      _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      data: SearchResultData
    ) => {
      const result = data.result;
      setResultingCompanies((currRes) => {
        const alreadyExists = currRes.some((res) => res.company === result.iri);
        if (alreadyExists) {
          return currRes;
        } else {
          return [...currRes, { ...result, company: result.iri }];
        }
      });
    },
    []
  );

  const onDeleteResultHandler = useCallback(
    ({
      selectedRows,
    }: {
      tableData: any[];
      selectedRows: string[];
      columns: any[];
    }) => {
      const keys = Object.keys(selectedRows);
      setResultingCompanies((currRes) => {
        return currRes.filter((curr) => !keys.includes(curr.company));
      });
    },
    []
  );

  const onSaveHandler = (
    iri: string,
    resultingCompanies: (
      | (SuggestedCompetitor & CompanyData)
      | { company: string }
    )[]
  ) => {
    const highConfidenceSuggested = highConfidenceCompetitors.map(
      (value, _) => value.iri
    );
    const lowConfidenceSuggested = lowConfidenceCompetitors.map(
      (value, _) => value.iri
    );
    const inferredSuggested = inferrredCompetitors.map(
      (value, _) => value.company
    );
    const resultingCompaniesIris = new Set(
      resultingCompanies.map((value, _) => {
        if ((value as SuggestedCompetitor).iri) {
          return (value as SuggestedCompetitor).iri;
        } else {
          return value.company;
        }
      })
    );
    const highConfidenceSelected = highConfidenceSuggested.filter((value, _) =>
      resultingCompaniesIris.has(value)
    );
    const lowConfidenceSelected = lowConfidenceSuggested.filter((value, _) =>
      resultingCompaniesIris.has(value)
    );
    const inferredSelected = inferredSuggested.filter((value, _) =>
      resultingCompaniesIris.has(value)
    );
    const metrics = {
      high_confidence_suggested: highConfidenceSuggested,
      low_confidence_suggested: lowConfidenceSuggested,
      inferred_suggested: inferredSuggested,
      high_confidence_selected: highConfidenceSelected,
      low_confidence_selected: lowConfidenceSelected,
      inferred_selected: inferredSelected,
      user_added_companies: Array.from(resultingCompaniesIris.values()),
    };
    save(iri, resultingCompanies, metrics).then(() => {
      if (competitors?.data) {
        mutateCompetitors(
          {
            ...competitors,
            data: {
              ...competitors.data,
              competitors: resultingCompanies.map((r) => r.company),
            },
          },
          false
        );
      }
    });
  };

  const { name, djid, market_data } = companyData?.data.attributes ?? {};

  // Utility variable to know if there are unsaved changes
  const haveResultsChanged = useMemo(() => {
    const savedCompetitors = competitors?.data.competitors ?? [];
    const currentCompetitors = resultingCompanies.map((r) => r.company);
    return !_.isEqual(savedCompetitors.sort(), currentCompetitors.sort());
  }, [competitors, resultingCompanies]);

  useConfirmBrowserExit(haveResultsChanged);

  // UI
  return (
    <div>
      <Prompt
        when={haveResultsChanged}
        message="Are you sure you want to leave with unsaved changes?"
      />
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <IconButton
          aria-label="go back"
          color="primary"
          onClick={history.goBack}
          size="medium"
        >
          <ArrowBackIcon />
        </IconButton>
        <h1 style={{ margin: 0 }}>{name}</h1>
        <StyledSubtitle>{"•"}</StyledSubtitle>
        <StyledSubtitle>{djid?.preferred_label}</StyledSubtitle>
        {market_data?.primary_listing && (
          <>
            <StyledSubtitle>{"•"}</StyledSubtitle>
            <StyledSubtitle>
              <span>{`${market_data.primary_listing?.exchange_label}: `}</span>
              <span style={{ color: "black" }}>
                {market_data.primary_listing?.ticker}
              </span>
            </StyledSubtitle>
          </>
        )}
      </div>
      <div style={{ display: "flex", height: "90vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 1em 0 0",
            borderRight: "1px solid #e4e4e4",
            // borderRadius: "5px",
            overflowY: "auto",
            flexGrow: 2,
            flexShrink: 4,
            flexBasis: "30em",
          }}
        >
          {suggestedError ? (
            <span>There was an error fetching suggestions</span>
          ) : !suggested ? (
            <span>
              Loading suggestions
              <CircularProgress size="2em" />
            </span>
          ) : (
            <>
              <Accordion
                expanded={expanded === "high"}
                onChange={handleChange("high")}
              >
                <AccordionSummary>High Confidence</AccordionSummary>
                <AccordionDetails
                  style={{
                    flexDirection: "column",
                    maxHeight: "60vh",
                    overflow: "auto",
                  }}
                >
                  <CompetitorsTable
                    data={highConfidenceCompetitors}
                    title={""}
                    selectedRows={resultingCompanies}
                    onSelectRowsChange={onSelectCandidateChange}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "low"}
                onChange={handleChange("low")}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary>Low Confidence</AccordionSummary>
                <AccordionDetails
                  style={{
                    flexDirection: "column",
                    maxHeight: "60vh",
                    overflow: "auto",
                  }}
                >
                  <CompetitorsTable
                    data={lowConfidenceCompetitors}
                    title={""}
                    selectedRows={resultingCompanies}
                    onSelectRowsChange={onSelectCandidateChange}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "inferred"}
                onChange={handleChange("inferred")}
              >
                <AccordionSummary>Inferred</AccordionSummary>
                <AccordionDetails
                  style={{
                    flexDirection: "column",
                    maxHeight: "60vh",
                    overflow: "auto",
                  }}
                >
                  <CompetitorsTable
                    data={inferrredCompetitors}
                    title={""}
                    selectedRows={resultingCompanies}
                    onSelectRowsChange={onSelectCandidateChange}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            flexGrow: 2,
            flexBasis: "4.5em",
          }}
        >
          <div
            style={{
              overflowY: "auto",
              marginBottom: "2em",
              height: "calc(100% - 5em)",
            }}
          >
            <CompetitorsResultTable
              data={resultingCompanies}
              deleteHandler={onDeleteResultHandler}
              onAddHandler={onManualAddHandler}
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            style={{ alignSelf: "flex-end" }}
            onClick={() => onSaveHandler(iri, resultingCompanies)}
            disabled={!haveResultsChanged}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Competitors;
