import { TableCell, TableCellProps } from "@material-ui/core";
import React from "react";

function BaseLineAlignedTableCell(
  props: JSX.IntrinsicAttributes & TableCellProps
) {
  return (
    <TableCell
      {...props}
      style={{ verticalAlign: "initial", padding: "0.75em" }}
    >
      {props.children}
    </TableCell>
  );
}

export default BaseLineAlignedTableCell;
