import {
  MatchingJobsTableBody,
  SelectedRowsDict,
} from "@pulsar/matching/types";
import { CircularProgress, Divider, Fab } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import React from "react";
import Table, {
  DateColumnFilter,
  filterDateInMonthYear,
  SelectColumnFilter,
} from "../components/Table";
import AddIcon from "@material-ui/icons/Add";
import { deleteJob } from "../utils/matchUtils";
import { useCancelableAuthedSWR, useRouter } from "../utils/hooks";
import {useOktaAuth} from "@okta/okta-react";

function formatMatchingJobs(jobs: any[]) {
  return jobs.reduce((acc, curr) => {
    acc.push({
      id: curr.id,
      name: curr.name,
      updatedAt: curr.updated_at,
      lastModifiedBy: curr.last_updated_by,
    });
    return acc;
  }, [] as MatchingJobsTableBody[]);
}

export default function MatchingList() {
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate } = useRouter();
  const locationEndpoint = `${process.env.REACT_APP_API}/matching_jobs`;
  const [formattedData, setformattedData] = React.useState([]);
  const [{ data, error, mutate }] = useCancelableAuthedSWR(locationEndpoint, {
    revalidateOnFocus: false, // TODO: Reactivate this option and figure out a way to avoid crashing while showing stale data
  });

  React.useEffect(() => {
    data && setformattedData(formatMatchingJobs(data));
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        Cell: ({ row }: { row: any }) => {
          const id = row.original.id;
          return (
            <Link
              to={{
                pathname: `/matching/${id}`,
                state: { name: row.original.name },
              }}
            >
              {row.original.name}
            </Link>
          );
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Updated At",
        id: "updatedAt",
        accessor: "updatedAt",
        Cell: ({ row }: { row: any }) => {
          const date = Date.parse(row.original.updatedAt)
            ? new Date(row.original.updatedAt).toLocaleString()
            : null;
          return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
        },
        Filter: DateColumnFilter,
        filter: filterDateInMonthYear,
      },
      {
        id: "lastModifiedBy",
        accessor: "lastModifiedBy",
        Header: "Last modified by",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    []
  );
  const memoizedHandleDeleteCallback = React.useCallback(
    async ({
      tableData,
      selectedRows,
    }: {
      tableData: any[];
      selectedRows: SelectedRowsDict;
    }) => {
      const idsToDelete = (tableData as any[])
        .filter((e, index) => selectedRows[index])
        .map((e) => e.id) as string[];

      try {
        const newData = data.filter(
          (m: MatchingJobsTableBody) => !idsToDelete.includes(m.id.toString())
        );
        await deleteJob({
          idsToDelete: idsToDelete,
          token: authState.accessToken!.accessToken,
        });
        mutate(newData);
        enqueueSnackbar("Successfully deleted", { variant: "success" });
      } catch {
        enqueueSnackbar("Something went wrong, please try again later", {
          variant: "error",
        });
      }
    },
    [data, enqueueSnackbar, authState.accessToken, mutate]
  );
  return (
    <div>
      <h1>Matching Jobs</h1>
      <Divider
        variant="middle"
        style={{ marginTop: "3.5em", marginLeft: "-1em", position: "relative" }}
      />
      <Fab
        color="primary"
        aria-label="CREATE"
        variant="extended"
        onClick={() => navigate(`/matching/new`)}
        style={{ position: "relative", top: "-2em" }}
      >
        <AddIcon /> Create
      </Fab>
      {!data || data?.message ? (
        <div>
          {error && <div>There was an error fetching cases, trying again</div>}
          <CircularProgress />
        </div>
      ) : (
        <Table
          columns={columns}
          data={formattedData}
          hasDelete
          deleteHandler={memoizedHandleDeleteCallback}
          setData={() => {}}
          updateMyData={() => {}}
          skipPageReset={false}
          renderRowSubComponent={() => {}}
          customRowRender={null}
          selectedRows={[]}
          onSelectedRowsChange={() => {}}
        />
      )}
    </div>
  );
}
