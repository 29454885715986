import { CircularProgress, LinearProgress } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { CellProps } from "react-table";

import Table, {
  filterMarketCapInCategories,
  MarketCapFilter,
  SelectColumnFilter,
} from "../components/Table";
import { categorizeMarketCap } from "../utils/company";
import { useCompaniesInBatches } from "../utils/hooks";
import _ from "lodash";

export default function CompetitorsList(): JSX.Element {
  const {
    loading,
    loadedCompanies,
    error,
    stableCompanies,
  } = useCompaniesInBatches();

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableFilter: true,
        Cell: ({ value, row }: CellProps<any>) => {
          return (
            <NavLink
              to={`competitors/${encodeURIComponent(row.original.company)}`}
            >
              {value}
            </NavLink>
          );
        },
      },
      {
        Header: "Ticker",
        id: "ticker",
        filter: "includes",
        Filter: SelectColumnFilter,
        disableFilter: true,
        accessor: (originalRow: any) => {
          const primaryExchange = originalRow?.market_data?.primary_exchange;
          const listings = originalRow?.market_data?.listings;
          const primaryTicker =
            originalRow?.market_datta?.primary_listing?.ticker;
          // We get the min length ticker as a hack since we don't have the primary ticker in the response.
          // Since it's usually the shortest one, we select that as the primary one
          //TODO: Remove the shortest trick once primary tickers have been all loaded
          const ticker =
            primaryTicker ??
            _.chain(listings)
              .filter(
                (listing: { exchange_label: string }) =>
                  listing.exchange_label === primaryExchange
              )
              .map("ticker")
              .minBy((x: string) => x.length)
              .value();
          return ticker;
        },
      },
      {
        Header: "Industry",
        filter: "includes",
        Filter: SelectColumnFilter,
        accessor: (originalRow: any) => {
          const industry =
            originalRow?.djid?.preferred_label ||
            originalRow?.djii?.preferred_label;
          return industry;
        },
      },
      {
        Header: "Market Cap",
        id: "market_cap.value",
        accessor: "market_cap.value",
        Filter: MarketCapFilter,
        filter: filterMarketCapInCategories,
        Cell: ({ value }: CellProps<any>) => {
          return <span>{categorizeMarketCap(value)}</span>;
        },
      },
      {
        Header: "Exchange",
        accessor: "market_data.primary_exchange",
        filter: "equals",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Last Updated",
        accessor: "ingested_at",
        filter: "equals",
        Filter: SelectColumnFilter,
      },
    ],
    []
  );

  return (
    <>
      <h2 style={{ margin: 0 }}>Competitors</h2>
      {error && loadedCompanies.length < 1 ? (
        <div>There was an error fetching companies {JSON.stringify(error)}</div>
      ) : loadedCompanies.length < 1 ? (
        <CircularProgress />
      ) : (
        <>
          {loading && (
            <LinearProgress
              variant="determinate"
              value={Math.ceil(
                (loadedCompanies.length / stableCompanies.length) * 100
              )}
            />
          )}
          <Table
            data={loadedCompanies}
            columns={columns}
            title={""}
            setData={() => {}}
            updateMyData={() => {}}
            customRowRender={null}
            skipPageReset={false}
            renderRowSubComponent={() => {}}
            selectedRows={() => {}}
            hasSelect={false}
            onSelectedRowsChange={() => {}}
          />
        </>
      )}
    </>
  );
}
