import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDeepSearch } from "../utils/hooks";

/**
 *
 * React component that loads deep search rows
 */
export default function DeepSearch({
  parentRow,
  visibleColumns,
  appendSubRows,
  numberOfSkeletonRows = 10,
}) {
  function onSuccess(results) {
    appendSubRows(parseInt(parentRow.id), results);
  }
  const [{ isLoading, error }, fetchDeepSearchCompanies] = useDeepSearch(
    parentRow.original,
    onSuccess
  );

  if (isLoading) {
    return (
      <>
        {/* Make several loading rows to mimic the size of the expected response*/}
        {Array.from(Array(numberOfSkeletonRows), () => {
          return (
            <TableRow>
              <TableCell />
              <TableCell colSpan={visibleColumns.length - 1}>
                <Typography variant="h4">
                  <Skeleton />
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  }

  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={visibleColumns.length - 2}>
        <div>
          Supplemental search will attempt to identify the company provided in a
          supplemental database. This might take a few seconds.
        </div>
        {error && (
          <div style={{ color: "red" }}>
            *There was an error perforrming deep search, please try again later
          </div>
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={fetchDeepSearchCompanies}
        >
          Supplemental Search
        </Button>
      </TableCell>
    </TableRow>
  );
}
