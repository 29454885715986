import { Metrics, TransitiveCompetitor } from "@pulsar/types/competitors";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import fetcherWithToken from "./fetcher";
import {useOktaAuth} from "@okta/okta-react";

export function saveCompetitors({
  companyIri,
  candidates,
  metrics,
  token,
}: {
  companyIri: string;
  candidates: Array<{ company: string }>;
  metrics: Metrics;
  token: string;
}) {
  const iris = candidates.map((c) => c.company);
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/competitors?company=${companyIri}`,
    token,
    {
      method: "POST",
      body: JSON.stringify({ iris, metrics }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function getCompetitors({
  companyIri,
  token,
}: {
  companyIri: string;
  token: string;
}) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/competitors?company=${companyIri}`,
    token,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

//TODO: Export all component state and effect logic to this controller
export function useCompetitorsController() {
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const token = authState.accessToken!.accessToken;
  function save(
    companyIri: string,
    candidates: Array<{ company: string }>,
    metrics: Metrics
  ) {
    return saveCompetitors({ companyIri, candidates, metrics, token})
      .then((data) => {
        enqueueSnackbar("Competitors saved", { variant: "success" });
        return data;
      })
      .catch((error) =>
        enqueueSnackbar("Something went wrong", { variant: "error" })
      );
  }

  const fetchCompetitors = useCallback(
    (companyIris: string[]): Promise<TransitiveCompetitor[]> => {
      const competitors: Promise<TransitiveCompetitor[]>[] = companyIris.map(
        (companyIri) =>
          getCompetitors({ companyIri, token }).then((data) => {
            return data.data.competitors.map(
              (competitor: string) =>
                ({
                  parentCompany: companyIri,
                  company: competitor,
                } as TransitiveCompetitor)
            );
          })
      );

      return Promise.allSettled(competitors).then((res) =>
        res.flatMap(
          (r) => (r as PromiseFulfilledResult<TransitiveCompetitor[]>)?.value
        )
      );
    },
    [token]
  );

  return {
    save,
    fetchCompetitors,
  };
}

export function updateCompanies<T>(
  companiesInfo: Array<T & { company: string }>,
  populatedCompanies: Array<T & { company: string }>
): Array<T & { company: string }> {
  const companiesToUpdate = companiesInfo.map((company) => {
    const incomingIri = company.company;
    const existingCompany = populatedCompanies.find((populatedCompany) => {
      const iri = populatedCompany.company;
      return iri === incomingIri;
    });

    const merged = {
      ...existingCompany,
      ...company,
    };
    return merged;
  });

  const updatedCompanies = populatedCompanies.map((populatedCompany) => {
    const existingUpdatedCompany = companiesToUpdate.find(
      (company) => company.company === populatedCompany.company
    );
    if (existingUpdatedCompany) {
      return existingUpdatedCompany;
    } else {
      return populatedCompany;
    }
  });

  const missingCompanies = companiesToUpdate.filter(
    (company) => !updatedCompanies.some((uc) => uc.company === company.company)
  );

  return [...updatedCompanies, ...missingCompanies];
}

export function keepNew<T>(
  companiesInfo: Array<T & { company: string }>,
  populatedCompanies: Array<T & { company: string }>
) {
  return companiesInfo;
}
