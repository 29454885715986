import { CircularProgress, Divider, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Table, { SelectColumnFilter } from "../components/Table";
import LoadingButton from "../components/LoadingButton";
import { SelectedRowsDict } from "@pulsar/matching/types";
import { createFilter, deleteFilters } from "../utils/filterEndpointUtils";
import { useCancelableAuthedSWR, useRouter } from "../utils/hooks";
import {useOktaAuth} from "@okta/okta-react";

export default function FiltersList() {
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate } = useRouter();
  const filters = `${process.env.REACT_APP_API}/filters`;
  const [isLoading, setIsLoading] = useState(false);
  const [{ data, error, mutate }] = useCancelableAuthedSWR(filters, {
    revalidateOnFocus: false,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Filter Name",
        Cell: ({ row }: { row: any }) => {
          return (
            <Link to={`/filters/${encodeURIComponent(row.original.id)}`}>
              {row.original.attributes.name || row.original.id}
            </Link>
          );
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    []
  );

  const memoizedHandleDeleteCallback = React.useCallback(
    async ({
      tableData,
      selectedRows,
    }: {
      tableData: any[];
      selectedRows: SelectedRowsDict;
    }) => {
      try {
        const idsToDelete = (tableData as any[])
          .filter((e, index) => selectedRows[index])
          .map((e) => e.id) as string[];

        const newData = {
          data: [
            ...data?.data.filter(
              (f: { id: string }) => !idsToDelete.includes(f.id)
            ),
          ],
        };
        await deleteFilters({ ids: idsToDelete, token: authState.accessToken!.accessToken });
        mutate(newData);
        enqueueSnackbar("Successfully deleted", { variant: "success" });
      } catch {
        enqueueSnackbar("Something went wrong, please try again later", {
          variant: "error",
        });
      }
    },
    [data, enqueueSnackbar, authState.accessToken, mutate]
  );

  async function handleCreateFilter() {
    try {
      setIsLoading(true);
      const filter = await createFilter({
        elements: [],
        token: authState.accessToken!.accessToken,
      });
      return navigate(`/filters/${filter.data.id}`);
    } catch (e) {
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <h1>Filters</h1>
      <Divider
        variant="middle"
        style={{ marginTop: "3.5em", marginLeft: "-1em", position: "relative" }}
      />
      <div
        style={{
          position: "relative",
          marginTop: "-1.75em",
          marginBottom: "5em",
        }}
      >
        <LoadingButton
          loading={isLoading}
          containerStyle={{ position: "absolute" }}
        >
          <Fab
            color="primary"
            aria-label="CREATE"
            variant="extended"
            disabled={isLoading}
            onClick={handleCreateFilter}
          >
            <AddIcon /> Create
          </Fab>
        </LoadingButton>
      </div>
      {!data || data?.message ? (
        <div>
          {error && (
            <div>There was an error fetching filters, trying again</div>
          )}
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={data.data}
          columns={columns}
          setData={() => {}}
          updateMyData={() => {}}
          skipPageReset={false}
          renderRowSubComponent={() => {}}
          customRowRender={null}
          selectedRows={[]}
          onSelectedRowsChange={() => {}}
          hasDelete
          deleteHandler={memoizedHandleDeleteCallback}
        />
      )}
    </div>
  );
}
