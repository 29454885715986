const API_URL = process.env.REACT_APP_API;

/**
 * This map facilitates switching the api url for test purposes for individual resources
 */
const RESOURCE_MAP = {
  companies: `${API_URL}/companies`,
  competitors: `${API_URL}/competitors`,
};

function build(resource: keyof typeof RESOURCE_MAP, route?: string) {
  return `${RESOURCE_MAP[resource]}${route}`;
}

export default {
  build,
};
