import React from "react";
import { Row, TableRowProps } from "react-table";
import { SearchResultData } from "semantic-ui-react";
import _ from "lodash";

import CompanyRowAsync from "./CompanyRowAsync";
import SearchComponent from "./Search";
import Table, { SelectColumnFilter } from "./Table";

interface CompetitorsResultTableProps {
  data: any[];
  deleteHandler: ({
    tableData,
    selectedRows,
    columns,
  }: {
    tableData: any;
    selectedRows: any;
    columns: any;
  }) => void;
  onAddHandler: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: SearchResultData
  ) => void;
}

// Render Props
function customRowRender({
  row,
  rowProps,
  visibleColumns,
}: {
  row: Row<any>;
  rowProps: TableRowProps;
  visibleColumns: any;
}) {
  return (
    <CompanyRowAsync
      row={row}
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      fetchRowKey="company"
    />
  );
}

export default function CompetitorsResultTable({
  data,
  deleteHandler,
  onAddHandler,
}: CompetitorsResultTableProps) {
  const renderAddSearchComponent = React.useCallback(() => {
    return (
      <SearchComponent
        onResultSelect={onAddHandler}
        icon={"add"}
        placeholder={"Add a company"}
      />
    );
  }, [onAddHandler]);
  return (
    <Table
      data={data}
      columns={[
        {
          Header: "Company Name",
          accessor: "company_name",
          Filter: SelectColumnFilter,
          filter: "includes",
          disableFilter: true,
        },
        {
          Header: "Ticker",
          filter: "includes",
          disableFilter: true,
          Filter: SelectColumnFilter,
          accessor: (originalRow: any) => {
            const primaryExchange = originalRow?.market_data?.primary_exchange;
            const listings = originalRow?.market_data?.listings;
            const primaryTicker =
              originalRow?.market_datta?.primary_listing?.ticker;
            // We get the min length ticker as a hack since we don't have the primary ticker in the response.
            // Since it's usually the shortest one, we select that as the primary one
            //TODO: Remove the shortest trick once primary tickers have been all loaded
            const ticker =
              primaryTicker ??
              _.chain(listings)
                .filter(
                  (listing: { exchange_label: string }) =>
                    listing.exchange_label === primaryExchange
                )
                .map("ticker")
                .minBy((x: string) => x.length)
                .value();
            return ticker;
          },
        },
        {
          Header: "Main Exchange",
          accessor: "market_data.primary_exchange",
          filter: "equals",
          Filter: SelectColumnFilter,
        },
      ]}
      title={"Results"}
      setData={() => {}}
      updateMyData={() => {}}
      customRowRender={customRowRender}
      skipPageReset={false}
      renderRowSubComponent={() => {}}
      selectedRows={[]}
      onSelectedRowsChange={() => {}}
      addComponent={renderAddSearchComponent}
      hasDelete={true}
      deleteHandler={deleteHandler}
      getRowId={(row: any, relativeIndex: any, parent: any) =>
        parent ? [parent.id, row.company].join(".") : row.company
      }
      tableElementCustomProps={{ stickyHeader: true }}
      tableFooterCustomProps={{
        style: {
          left: 0,
          bottom: 0, // <-- KEY
          zIndex: 2,
          position: "sticky",
          backgroundColor: "white",
        },
      }}
      toolBarConfig={{
        hasLocalSearch: false,
        hasFilterColumns: false,
        hasRowFilters: false,
        hasDelete: true,
      }}
    />
  );
}
