import fetcherWithToken from "./fetcher";

async function getSignals({
  key,
  token,
  controller,
}: {
  key: string;
  token: string;
  controller?: AbortController;
}) {
  const data = await fetcherWithToken(
    `${process.env.REACT_APP_API}${key}`,
      token,
    { signal: controller?.signal }
  );
  return data;
}

export { getSignals };
