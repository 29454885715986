import fetcherWithToken from "./fetcher";

export type PriorityQueue = {
  clients: ClientsQueue[];
  filters: FiltersQueue[];
  templates: TemplatesQueue[];
};
export type ClientsQueue = {
  period: string;
  id: string;
  queue_percentage: number;
};
export type FiltersQueue = {
  period: string;
  id: string;
  queue_percentage: number;
};
export type TemplatesQueue = {
  period: string;
  template: string;
  queue_percentage: number;
};

export function updatePriorityQueue({
  priorityQueue,
  token,
}: {
  priorityQueue: any;
  token: string;
}): Promise<any> {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/priority/queue`,
    token,
    {
      method: "POST",
      body: JSON.stringify(priorityQueue),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
