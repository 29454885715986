import React, { useState } from "react";
import { CompanyData, MarketData } from "@pulsar/matching/types";
import { useOktaAuth } from "@okta/okta-react";
import endpoints from "../utils/endpoints";
import fetcherWithToken from "../utils/fetcher";
import { formatCompanyInfoData } from "../utils/company";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function Company() {
  const { authState } = useOktaAuth();
  const [textBox, setTextBox] = useState("");
  const [iri, setIri] = useState("");
  const [companyDataState, setCompanyDataState] = useState<CompanyData>();
  const history = useHistory();

  function handleClick() {
    setIri(textBox);
    const companyInfoEndpoint = endpoints.build("companies", `?iri=${iri}`);

    async function fetchingData() {
      return await fetcherWithToken(
        companyInfoEndpoint,
        authState.accessToken!.accessToken
      )
        .then((c) => setCompanyDataState(c))
        .catch((e) => {
          alert(e.message);
          console.error(e.message);
        });
    }

    fetchingData();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newIri = event.target.value;
    setIri(newIri);
    setTextBox(newIri);
    console.info(textBox);
  }

  function companyDataPresentation(companyData: CompanyData | undefined) {
    function definedOrEmpty(
      prefix: string,
      s: string | string[] | null | undefined,
      suffix: string
    ) {
      if (s !== undefined && s !== null) {
        return prefix + s.toLocaleString() + suffix;
      } else {
        return "";
      }
    }

    function dunsPresentation(d: string | string[] | null | undefined) {
      if (d !== undefined && d !== null) {
        if (typeof d === "string") {
          const dAsString = d as string;
          return "duns: " + dAsString;
        } else {
          const dAsArray = d as string[];
          return "duns: " + dAsArray.join(", ");
        }
      } else {
        return "";
      }
    }

    function listingsPresentation(mk: MarketData | undefined | null) {
      if (mk !== undefined && mk !== null) {
        return mk.listings.map((x) => (
          <ul>{x.ticker + ", " + x.exchange_label + ", " + x.exchange_iri}</ul>
        ));
      } else {
        return <div> </div>;
      }
    }

    if (companyData !== undefined && companyData !== null) {
      const out = formatCompanyInfoData(companyData);

      const name = out.name;
      const altNames = (
        <>
          <h4>Alternative Names</h4>
          <ul>{out.altname!.toString()}</ul>
        </>
      );

      const djidCodePresentation =
        definedOrEmpty("", out.djid?.code, "  ") +
        definedOrEmpty(" label: ", out.djid?.preferred_label, ".");

      return (
        <>
          <h1>{name}</h1>
          {altNames}
          <h4>Address</h4>
          {definedOrEmpty("", out.city, ",") +
            definedOrEmpty(" ", out.state, "") +
            definedOrEmpty(" ", out.country, ".")}
          <h4>Ids and references</h4>
          <ul>
            <li>{djidCodePresentation}</li>
            <li>{dunsPresentation(out.duns)}</li>
            <li>fcode: {out.fcode}</li>
          </ul>
          <h4>Primary Listing</h4>
          {listingsPresentation(out.market_data)}
          <h4>URL</h4>
          <div>{definedOrEmpty("", out.url, "")}</div>
        </>
      );
    } else {
      console.debug("Company not defined");
      return <h1> </h1>;
    }
  }

  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <label>
        <h2>Company Information </h2>
        <input type="text" onChange={(e) => handleChange(e)} name="iri" />
      </label>
      <button type="submit" onClick={() => handleClick()}>
        Search IRI
      </button>
      <div>{companyDataPresentation(companyDataState)}</div>
    </>
  );
}
