import React, {useEffect, useReducer, useState} from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  TextField,
} from "@material-ui/core";
import { useInterval, useRouter } from "../utils/hooks";
import LoadingButton from "../components/LoadingButton";
import {
  buildMatchingDataFromCsv,
  buildMatchingDataFromMatchingApi,
  fetchMatchingJob,
  insertJob,
} from "../utils/matchUtils";
import { matchReducer, defaultInitialState } from "../utils/matchReducer";
import { theme } from "../utils/pulsarTheme";
import BetterMatchingTable from "../components/BetterMatchingTable";
import { MatchingData, SelectedRowsDict } from "@pulsar/matching/types";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useOktaAuth} from "@okta/okta-react";
import {UserClaims} from "@okta/okta-auth-js/lib/types/UserClaims";

export default function NewMatch() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | undefined>();

  const { navigate } = useRouter();
  const history = useHistory();
  const [
    { status, job_id, results, submitStatus, name },
    dispatch,
  ] = useReducer(matchReducer, {
    ...defaultInitialState,
  });

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(undefined);
    } else {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  useInterval(
    async () => {
      if (job_id) {
        try {
          const matchingResult = await fetchMatchingJob({
            jobId: job_id,
            token: authState.accessToken!.accessToken,
          });
          if (matchingResult?.status === "DONE") {
            dispatch({
              type: "SET_RESULTS",
              payload: {
                results: buildMatchingDataFromMatchingApi(matchingResult),
              },
            });
          }
        } catch (error) {
          dispatch({
            type: "SET_ERROR",
          });
        }
      }
    },
    status === "Pending" ? 5000 : null
  );

  async function uploadFileHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;
    const rawFile = await event.target.files[0].text();
    try {
      const matchData = await buildMatchingDataFromCsv({
        rawStringCsv: rawFile,
        token: authState.accessToken!.accessToken,
      });
      dispatch({ type: "SET_PENDING", payload: { job_id: matchData.job_id } });
    } catch (error) {
      dispatch({ type: "SET_ERROR" });
      return;
    }
  }

  const memoizedSaveTable = React.useCallback(
    async function handleSaveTable({
      data,
      selectedRows,
    }: {
      data: MatchingData[];
      selectedRows: SelectedRowsDict;
    }) {
      dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
      try {
        await insertJob({
          userEmail: userInfo?.email!,
          name: name,
          body: { data, selectedRows },
          token: authState.accessToken!.accessToken,
          isWip: false,
        });
        return navigate("/matching");
      } catch (error) {
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    },
    [authState.accessToken, name, navigate, userInfo]
  );

  const renderButtons = React.useCallback(
    ({ data, selectedRows }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          flexWrap: "wrap",
          marginTop: "1em",
        }}
      >
        <LoadingButton loading={submitStatus.isLoading && !submitStatus.isWip}>
          <Button
            onClick={() => memoizedSaveTable({ data, selectedRows })}
            disabled={submitStatus.isLoading}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </LoadingButton>
        <Button
          onClick={() => navigate("/matching")}
          disabled={submitStatus.isLoading}
          style={{ color: theme.palette.contrast.main }}
          variant="text"
        >
          Cancel
        </Button>
      </div>
    ),
    [memoizedSaveTable, navigate, submitStatus.isLoading, submitStatus.isWip]
  );
  function handleNameChange(e: { target: { value: any } }) {
    dispatch({ type: "SET_NAME", payload: { name: e.target.value } });
  }

  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <h1>New Matching Job</h1>
      <p>To start a new matching job please upload a csv file.</p>
      <Input type="file" onChange={uploadFileHandler}></Input>
      <div style={{ marginTop: "1em" }}>
        {status === "Pending" && <CircularProgress />}
        {status === "Done" && (
          <>
            <TextField
              id="filled-full-width"
              label="Name*"
              style={{ marginBottom: "1em" }}
              placeholder="choose a name for this job"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              value={name}
              onChange={handleNameChange}
            />
            <BetterMatchingTable
              initialData={results}
              renderOnSaveButtons={renderButtons}
            />
          </>
        )}
        {status === "Error" && (
          <div>
            There was an error processing the request, please try again later
          </div>
        )}
      </div>
    </>
  );
}
