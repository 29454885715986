import { CompanyData } from "@pulsar/matching/types";
import {
  CompetitorInfo,
  CompetitorsResponse,
  SearchCompetitorsResponse,
  SuggestedCompetitor,
  TransitiveCompetitor,
} from "@pulsar/types/competitors";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR, { ConfigInterface, keyInterface } from "swr";

import { updateCompanies, useCompetitorsController } from "./competitors";
import endpoints from "./endpoints";
import fetcherWithToken from "./fetcher";
import {useOktaAuth} from "@okta/okta-react";

export function useInterval(callback: () => any, delay: number | null) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

export function useRouter() {
  const location = useLocation();
  const history = useHistory();

  function navigate(to: string, { replace = false } = {}) {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  return {
    location,
    navigate,
  };
}

export function useCombinedRefs(...refs: any[]): React.MutableRefObject<any> {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export function useAbortController() {
  const abortControllerRef = useRef<AbortController>();
  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  useEffect(() => {
    return () => getAbortController().abort();
  }, [getAbortController]);

  const getSignal = useCallback(() => getAbortController().signal, [
    getAbortController,
  ]);

  return { getAbortController, getSignal };
}

export function useCancelableAuthedSWR<Data = any, Error = any>(
  key: keyInterface,
  opts?: ConfigInterface<any, any, (...args: any) => any | Promise<any>>,
  requestInit?: RequestInit
) {
  const { authState } = useOktaAuth();
  const { getAbortController, getSignal } = useAbortController();
  return [
    useSWR<Data, Error>(
      key,
      (url) =>
        fetcherWithToken(url,authState.accessToken!.accessToken, {
          signal: getSignal(),
          headers: {
            "Cache-Control": "max-age=86400",
            ...requestInit?.headers,
          },
          ...requestInit,
        }),
      opts
    ),
    getAbortController(),
  ] as const;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useDeepSearch(
  payload: any,
  onSuccess: (results: any[]) => void
) {
  interface DeepSearchState {
    isLoading: boolean;
    error: boolean | null;
    searchResult: any[] | null;
  }

  // In order to get results from the service, null and empty string should be avoided
  const filteredNonNullValues = Object.keys(payload).reduce<
    Record<string, string>
  >((acc, curr) => {
    if (payload[curr] && payload[curr] !== "") {
      acc[curr] = payload[curr];
    }
    return acc;
  }, {});
  const initialState = { isLoading: false, error: null, searchResult: null };
  const [state, setstate] = useState<DeepSearchState>(initialState);
  const { authState } = useOktaAuth();
  const url = `${process.env.REACT_APP_API}/companies/deepsearch`;

  async function fetchDeepSearchCompanies() {
    try {
      setstate({ ...state, isLoading: true, error: false });
      const results = await fetcherWithToken(url, authState.accessToken!.accessToken, {
        method: "POST",
        body: JSON.stringify({ data: filteredNonNullValues }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setstate({ ...state, isLoading: false, searchResult: results });
      onSuccess(results);
    } catch {
      setstate({ ...state, isLoading: false, error: true });
    }
  }

  return [state, fetchDeepSearchCompanies];
}

export function chunk(list: any[], n: number) {
  const range = [...Array(Math.ceil(list.length / n)).keys()];
  return range.map((x, i) => list.slice(i * n, i * n + n));
}

const memoryState: { [key: string]: any[] } = {};

export function useFetchInBatches<T, K>(
  items: Array<T>,
  chunks: number,
  fetcher: (arg: T) => Promise<K>,
  cacheKey?: string,
  updateFn?: (curr: Array<K>, acc: Array<K>) => Array<K>
) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<K[]>([]);

  useEffect(() => {
    async function asyncBatch() {
      setLoading(true);
      const chunkedItems = chunk(items, chunks);
      const all = chunkedItems.reduce((accumulatorPromise, nextID) => {
        return accumulatorPromise.then(() => {
          const arr = nextID.map(fetcher);
          return Promise.all(arr).then((res) => {
            setState((state) => {
              const updatedCompanies = updateFn
                ? updateFn(res, state)
                : [...state, ...res];
              return updatedCompanies;
            });
            if (cacheKey) {
              const cache = memoryState[cacheKey];
              memoryState[cacheKey] = cache ? [...cache, ...res] : res;
            }
          });
        });
      }, Promise.resolve());
      all.then((res) => {
        setLoading(false);
      });
    }
    const flatState =
      cacheKey && memoryState[cacheKey]
        ? memoryState[cacheKey]?.flatMap((a) => a)
        : null;

    //Check if there's stuff to batch process
    if (items.length > 0) {
      // Check if cache exists
      if (flatState) {
        return setState(flatState);
      } else {
        // TODO: Improve this algorithm to only re-fetch the pending difference if partially stored in the cache
        asyncBatch();
      }
    } else {
      setState([]);
    }
  }, [cacheKey, chunks, fetcher, items, updateFn]);
  return { state, setState, loading };
}

export function useFetchCompany(
  formatter?: (data: any) => CompanyData,
  init?: RequestInit
) {
  const { authState } = useOktaAuth();
  const { getAbortController, getSignal } = useAbortController();
  return {
    fetchCompany: useCallback(
      (obj: string | { company: string } | { iri: string }) => {
        const iri =
          (obj as { company: string })?.company ??
          (obj as { iri: string })?.iri;
        return fetcherWithToken(
          `${process.env.REACT_APP_API}/companies/iri?iri=${iri ?? obj}`,
          authState.accessToken!.accessToken,
          {
            signal: getSignal(),
            headers: {
              "Cache-control": "max-age=86000",
              ...init?.headers,
            },
            ...init,
          }
        )
          .then((res: any) => {
            const result = iri
              ? { ...(obj as { company: string }), ...res }
              : res;
            return formatter ? formatter(result) : result;
          })
          .catch((exception) => {
            console.log(exception);
            return {
              company_name: "failed to fetch",
              company: iri ?? obj,
            } as CompanyData;
          });
      },
      [formatter, authState.accessToken, getSignal, init]
    ),
    controller: getAbortController(),
  };
}

export function useCompanyInfo(
  iri: string,
  opts?: ConfigInterface<any, any, (...args: any) => any | Promise<any>>,
  requestInit?: RequestInit
) {
  const companyInfoEndpoint = endpoints.build("companies", `?iri=${iri}`);
  return useCancelableAuthedSWR(
    companyInfoEndpoint,
    {
      revalidateOnFocus: false,
      ...opts,
    },
    requestInit
  );
}

export function useSuggestedCompetitors(
  iri: string,
  opts?: ConfigInterface<any, any, (...args: any) => any | Promise<any>>,
  requestInit?: RequestInit
) {
  const suggestedCompetitorsEndpoint = endpoints.build(
    "competitors",
    `/suggested?company=${iri}`
  );
  return useCancelableAuthedSWR<{
    competitors: SuggestedCompetitor[];
  }>(
    suggestedCompetitorsEndpoint,
    {
      revalidateOnFocus: false,
      ...opts,
    },
    requestInit
  );
}

export function useCompetitors(
  iri: string,
  opts?: ConfigInterface<any, any, (...args: any) => any | Promise<any>>,
  requestInit?: RequestInit
) {
  const competitorsEndpoint = endpoints.build("competitors", `?company=${iri}`);
  return useCancelableAuthedSWR<CompetitorsResponse>(
    competitorsEndpoint,
    {
      revalidateOnFocus: false,
      ...opts,
    },
    requestInit
  );
}

export function useInferredCompetitors(
  resultingCompanies: Array<
    (SuggestedCompetitor & CompanyData) | { company: string }
  >
) {
  const { fetchCompetitors } = useCompetitorsController();
  const [inferrredCompetitors, setInferredCompetitors] = useState<
    TransitiveCompetitor[]
  >([]);

  useEffect(() => {
    async function fetchCompetitorEffect() {
      const missingCompetitors = resultingCompanies.map(
        (c: { company: any }) => c.company
      );
      const result = await fetchCompetitors(missingCompetitors);
      setInferredCompetitors(result);
    }

    if (resultingCompanies.length > 0) {
      fetchCompetitorEffect();
    } else {
      setInferredCompetitors([]);
    }
  }, [fetchCompetitors, resultingCompanies]);

  return { inferrredCompetitors, setInferredCompetitors };
}

export function useSyncResultingWithInitialSelected(
  setResultingCompanies: React.Dispatch<
    React.SetStateAction<
      (
        | (SuggestedCompetitor & CompanyData)
        | {
            company: string;
          }
      )[]
    >
  >,
  competitors?: CompetitorsResponse
) {
  useEffect(() => {
    // Sync initial selected suggestions with resulting companies
    const initialResulting =
      competitors?.data.competitors?.map((c) => ({ company: c })) ?? [];
    setResultingCompanies(initialResulting);
  }, [competitors, setResultingCompanies]);
}

export function useCompanies(
  filter?: string,
  opts?: ConfigInterface<any, any, (...args: any) => any | Promise<any>>,
  requestInit?: RequestInit
) {
  const companiesIris = `${process.env.REACT_APP_API}/companies/filter?${filter}`;
  return useCancelableAuthedSWR(
    companiesIris,
    {
      revalidateOnFocus: false,
      ...opts,
    },
    requestInit
  );
}

const formatCompaniesInfoData = (data: any) => {
  const company_name = data.data.attributes.name;
  const company = data.data.id;
  return {
    ...data.data.attributes,
    company,
    company_name,
  };
};

/**
 * This hook is a (big) combination of useCompanies & useFetchInBatches, it fetches companies in batches and handles abort controller.
 * It also handles the specific case of fetching ingestion_date for competitors use case
 */
export function useCompaniesInBatches() {
  const US_LISTED_FILTER = "filter[exchange][iso2_country]=US";
  const { authState } = useOktaAuth();
  const { fetchCompany, controller } = useFetchCompany(formatCompaniesInfoData);
  const [{ data: companies, error }] = useCompanies(US_LISTED_FILTER);

  const stableCompanies: string[] = useMemo(
    () => (!companies ? [] : companies.data.attributes.iris),
    [companies]
  );

  // Stop fetching when unmounted
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  const { loading, state, setState } = useFetchInBatches<
    string,
    CompetitorInfo | CompanyData
  >(stableCompanies, 100, fetchCompany, US_LISTED_FILTER, updateCompanies);

  //TODO: Maybe remove this effect to its own since its just used for competitors right now
  useEffect(() => {
    async function fetchCompetitors() {
      const competitorEndpoint = `${process.env.REACT_APP_API}/competitors/search`;
      const { data }: SearchCompetitorsResponse = await fetcherWithToken(
        competitorEndpoint,
        authState.accessToken!.accessToken,
        {
          method: "POST",
          body: JSON.stringify({
            iris: stableCompanies,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      const formattedCompetitors = data.map((ci: CompetitorInfo) => ({
        iri: ci.company,
        ...ci,
      }));
      setState((currentCompanies) => {
        const something = updateCompanies(
          formattedCompetitors,
          currentCompanies
        );
        return something;
      });
    }
    if (stableCompanies.length > 0) {
      fetchCompetitors();
    }
  }, [stableCompanies, authState.accessToken, setState]);

  return { loading, loadedCompanies: state, error, stableCompanies };
}

/**
 * Display the native browser alert on unload when enabled
 * @param isEnabled boolean to enable/sync the effect to
 */
export function useConfirmBrowserExit(isEnabled: Boolean) {
  useEffect(() => {
    function alertUser(e: BeforeUnloadEvent) {
      if (isEnabled) {
        e.preventDefault();
        e.returnValue = "Are you sure want to leave with unsaved changes?"; //On some browsers this message will be ignored with a generic one
      }
    }

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [isEnabled]);
  return;
}
