import React, { useState } from "react";

import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import GlobalFilter from "./GlobalFilter";
import IconButton from "@material-ui/core/IconButton";
import { lighten, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import TableViewCol from "../components/TableViewCol";
import FilterIcon from "@material-ui/icons/FilterList";
import Popover from "./Popover";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [iconActive, setIconActive] = useState(null);
  const {
    numSelected,
    deleteHandler = () => {},
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    hasLocalSearch = true,
    hasFilterColumns = true,
    hasRowFilters = true,
    hasDelete = false,
    getToggleHideAllColumnsProps,
    allColumns,
    addComponent,
    title,
    exportData,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0 && hasDelete,
      })}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>
      {addComponent && numSelected === 0 ? (
        addComponent()
      ) : (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && hasDelete ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={deleteHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : hasLocalSearch ? (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : null}
      {exportData && (
        <Tooltip title={"Export Data"} disableFocusListener>
          <IconButton
            data-testid={"Export Data-iconButton"}
            aria-label={"Export Data"}
            // Right now we assume that there's only 1 export format
            // and that the user includes only the current view.
            onClick={() => exportData("csv", false)}
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
      {hasFilterColumns && (
        <Popover
          refExit={() => setIconActive(null)}
          classes={{ closeIcon: classes.filterCloseIcon }}
          trigger={
            <Tooltip title={"View Columns"} disableFocusListener>
              <IconButton
                data-testid={"View Columns-iconButton"}
                aria-label={"View Columns"}
                classes={{ root: iconActive === "viewcolumns" }}
                onClick={() => setIconActive("viewcolumns")}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
          }
          content={
            <TableViewCol
              columns={allColumns}
              getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            />
          }
        />
      )}

      {hasRowFilters && (
        <Popover
          refExit={() => setIconActive(null)}
          classes={{
            paper: classes.filterPaper,
            closeIcon: classes.filterCloseIcon,
          }}
          trigger={
            <Tooltip title={"filterTable"} disableFocusListener>
              <IconButton
                data-testid={"filterTable-iconButton"}
                aria-label={"filterTable"}
                classes={{ root: iconActive === "filter" }}
                onClick={() => setIconActive("filter")}
              >
                <FilterIcon />
              </IconButton>
            </Tooltip>
          }
          content={
            <div
              style={{
                marginTop: "1.5em",
              }}
            >
              {allColumns.map((column) => {
                return column.canFilter && !column.disableFilter
                  ? column.render("Filter")
                  : null;
              })}
            </div>
          }
        />
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteHandler: PropTypes.func,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  hasLocalSearch: PropTypes.bool,
  hasFilterColumns: PropTypes.bool,
  hasRowFilters: PropTypes.bool,
};

export default TableToolbar;
