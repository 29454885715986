import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: "1em 1.5em 1em 1.5em",
      fontFamily: "Roboto",
    },
    title: {
      marginLeft: "-7px",
      marginRight: "24px",
      fontSize: "14px",
      color: theme.palette.text.secondary,
      textAlign: "left",
      fontWeight: 500,
    },
    formGroup: {
      marginTop: "8px",
    },
    formControl: {},
    checkbox: {
      padding: "0px",
      width: "32px",
      height: "32px",
    },
    checkboxRoot: {},
    checked: {},
    label: {
      fontSize: "15px",
      marginLeft: "8px",
      color: theme.palette.text.primary,
    },
  }),
  { name: "MUIDataTableViewCol" }
);

const TableViewCol = ({
  columns,
  components = {},
  getToggleHideAllColumnsProps,
}) => {
  const classes = useStyles();
  const CheckboxComponent = components.Checkbox || Checkbox;

  return (
    <FormControl
      component={"fieldset"}
      className={classes.root}
      //   aria-label={textLabels.titleAria}
    >
      <Typography variant="caption" className={classes.title}>
        Show Columns
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          key={"select"}
          classes={{
            root: classes.formControl,
            label: classes.label,
          }}
          control={
            <CheckboxComponent
              color="primary"
              data-description="table-view-col"
              className={classes.checkbox}
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
              {...getToggleHideAllColumnsProps()}
            />
          }
          label={"Select All"}
        />
        {columns.map((column, index) => {
          return (
            column.display !== "excluded" &&
            column.viewColumns !== false && (
              <FormControlLabel
                key={column.id}
                classes={{
                  root: classes.formControl,
                  label: classes.label,
                }}
                control={
                  <CheckboxComponent
                    color="primary"
                    data-description="table-view-col"
                    className={classes.checkbox}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    {...column.getToggleHiddenProps()}
                  />
                }
                label={
                  typeof column.Header === "string" ? column.Header : column.id
                }
              />
            )
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

TableViewCol.propTypes = {
  /** Columns used to describe table */
  columns: PropTypes.array.isRequired,
  /** Options used to describe table */
  /** Callback to trigger View column update */
  onColumnUpdate: PropTypes.func,
  /** Extend the style applied to components */
  classes: PropTypes.object,
};

export default TableViewCol;
