import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { LowPriority } from "@material-ui/icons";
import AlbumOutlinedIcon from "@material-ui/icons/AlbumOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FilterListIcon from "@material-ui/icons/FilterList";
import React from "react";
import { NavLink, Redirect, Switch } from "react-router-dom";
import PulsarIcon from "../assets/pulsar.svg";

import { theme } from "../utils/pulsarTheme";
import Competitors from "./Competitors";
import CompetitorsList from "./CompetitorsList";
import Filter from "./Filter";
import FiltersList from "./FiltersList";
import MatchingList from "./MatchingList";
import NewMatch from "./NewMatch";
import Priority from "./Priority";
import SavedMatch from "./SavedMatch";
import Signals from "./Signals";
import { SecureRoute, useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js/lib/types/UserClaims";
import Company from "./Company";
import SearchIcon from "@material-ui/icons/Search";

export default React.memo(function Home() {
  const { authState, oktaAuth } = useOktaAuth();
  const isAuthenticated = authState.isAuthenticated ?? false;

  const [userInfo, setUserInfo] = React.useState<UserClaims | null>(null);

  React.useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  return (
    <div>
      <div
        style={{
          height: "100%",
          position: "fixed",
          padding: "0.875em",
          backgroundColor: theme.palette.primary.main,
          zIndex: 3,
        }}
      >
        <List
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: 0,
          }}
        >
          <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
            <img
              src={PulsarIcon}
              alt="Pulsar logo"
              style={{ height: "2.5em", margin: "auto" }}
            />
          </ListItem>
          <NavLink
            to="/matching"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"matching"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <CompareArrowsIcon />
                  <ListItemText>Matching</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/filters"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"filters"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <FilterListIcon />
                  <ListItemText>Filters</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/signals"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"signals"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <AlbumOutlinedIcon />
                  <ListItemText>Signals</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/priority"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"priority"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <LowPriority />
                  <ListItemText>Priority</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/competitors"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"competitors"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <BusinessIcon />
                  <ListItemText>Competitors</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/company"
            activeStyle={{
              fontWeight: "bold",
              color: theme.palette.primary.activeText,
            }}
            style={{ color: theme.palette.primary.inactiveText }}
          >
            <ListItem
              button
              key={"company"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <>
                  <SearchIcon />
                  <ListItemText>Company</ListItemText>
                </>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <ListItem style={{ marginTop: "auto" }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ padding: 0 }}
            >
              {isAuthenticated && userInfo ? (
                <Avatar alt="profile picture">
                  {userInfo.name?.charAt(0)}
                </Avatar>
              ) : (
                <Avatar alt="profile picture"></Avatar>
              )}
            </IconButton>
          </ListItem>
        </List>
      </div>
      <div
        style={{
          padding: "1em 2em 0em 9em",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Switch>
          <SecureRoute exact path="/matching/new" component={NewMatch} />
          <SecureRoute exact path="/matching/:id" component={SavedMatch} />
          <SecureRoute exact path="/matching" component={MatchingList} />
          <SecureRoute exact path="/filters/:id" component={Filter} />
          <SecureRoute exact path="/filters" component={FiltersList} />
          <SecureRoute exact path="/signals" component={Signals} />
          <SecureRoute exact path="/priority" component={Priority} />
          <SecureRoute exact path="/competitors" component={CompetitorsList} />
          <SecureRoute exact path="/company" component={Company} />
          <SecureRoute exact path="/competitors/:iri" component={Competitors} />
          <Redirect from="/" to="/matching" />
        </Switch>
      </div>
    </div>
  );
});
